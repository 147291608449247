import { Button } from '@mui/material'
import React from 'react'

const OrangeOutlinedButton = ({
  title,
  handleOnClick,
  sx,
  icon,
  isChip = false,
  isDisabled = false,
}) => {
  return (
    <Button
      variant='outlined'
      disabled={isDisabled}
      disableElevation={isChip}
      sx={{
        backgroundColor: '#FFFFFF',
        color: 'var(--primary-bg-color)',
        borderRadius: '6px',
        padding: '5px 16px',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '400',
        textTransform: 'none',
        border: '1px solid var(--primary-bg-color)',
        '&:hover': {
          backgroundColor: 'var(--primary-bg-color)',
          color: 'var(--primary-bg-color)',
          border: '1px solid var(--primary-bg-color)',
        },
        ...sx,
      }}
      onClick={handleOnClick}
    >
      {icon}
      {title}
    </Button>
  )
}

export default OrangeOutlinedButton
