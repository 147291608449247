import { Button, Grid, styled, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import AddIcon from '@mui/icons-material/Add'
import axios from '../../../InstanceAxios/InstanceAxios'
import { getShopInfo } from '../../../../utilities/StoredData'
import { useSearchParams, useNavigate, useParams } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import { TOTAL_COLLECTIONS } from '../../../../utilities/MixpanelConst'
import CachedIcon from '@mui/icons-material/Cached'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--primary-text-color)',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'var(--primary-text-color)',
    },
  },
})

const AddCollection = () => {
  const [collectionName, setCollectionName] = useState()
  const [collectionImage, setCollectionImage] = useState()
  const [image, setImage] = useState()
  const [collectionEdit, setCollectionEdit] = useState()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { cid } = useParams()
  //console.log(cid);

  const info = getShopInfo()
  // console.log(info);

  useEffect(() => {
    if (!cid) return
    const url = `/productCategories/getSingleProductCategory/${cid}`
    const getInfo = async () => {
      const res = await axios.get(url)
      setCollectionEdit(res.data.Product_Category)
      setCollectionName(collectionEdit?.category)
      setCollectionImage(collectionEdit?.img_url)
    }
    getInfo()
  }, [collectionEdit?.category, collectionEdit?.img_url, cid])
  //console.log(collectionEdit);

  const handleChange = (e) => {
    setCollectionName(e.target.value)
  }

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files

    setImage(e.target.files[0])

    const selectedFilesArray = Array.from(selectedFiles)

    const imagesArray = selectedFilesArray?.map((file) => {
      return URL.createObjectURL(file)
    })

    setCollectionImage(imagesArray)
  }
  // console.log('im', collectionImage);

  const validationTest = () => {
    if (collectionName === '') {
      return true
    }
    if (collectionImage?.length === 0) {
      return true
    }
  }

  const handleAddCollection = async () => {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('category', collectionName)
    formData.append('shop_id', info.shop_id)

    const url = `/productCategories/add`
    const res = await axios.post(url, formData)
    //console.log(res.data);

    //mixpanel
    if (res.data.status === 'S111') {
      mixpanel.track('Collection Added')
      mixpanel.people.increment(TOTAL_COLLECTIONS, 1.0)
    }

    res.data.status = 'S111'
    if (searchParams.get('redirect')) {
      return navigate(searchParams.get('redirect'))
    }
    navigate('/inventory/collections')
  }

  const handleUpdateCollection = async () => {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('category', collectionName)

    const url = `/productCategories/update/${cid}`
    const res = await axios.put(url, formData)
    console.log(res.data)
    res.data.status = 'S115' && navigate('/inventory/collections')
  }

  return (
    <div>
      <Grid container sx={{ marginTop: '48px', marginX: '48px', width: '93%' }}>
        <Grid item md={6}>
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              marginRight: '48px',
              textAlign: 'left',
              padding: '24px',
            }}
          >
            <Typography
              sx={{
                fontSize: '22px',
                color: 'var(--primary-text-color)',
                marginBottom: '16px',
              }}
            >
              About Collection
            </Typography>
            <CssTextField
              required
              fullWidth
              id='outlined-required'
              label=''
              name='collection_name'
              type='text'
              // error={validation?.product_name}
              placeholder='Enter a collection name'
              sx={{ marginBottom: '16px' }}
              value={collectionName}
              onChange={handleChange}
              InputLabelProps={{ sx: { fontFamily: 'Exo' } }}
              InputProps={{ sx: { fontFamily: 'Exo' } }}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            backgroundColor: '#FFFFFF',
            textAlign: 'left',
            padding: '24px',
          }}
        >
          <Typography
            sx={{ fontSize: '22px', color: 'var(--primary-text-color)' }}
          >
            Collection Image
          </Typography>
          <Box>
            {collectionImage ? (
              <Box sx={{ width: '379px', marginTop: '24px' }}>
                <img
                  src={collectionImage}
                  height='198px'
                  width='379px'
                  style={{
                    borderRadius: '8px',
                    marginRight: '24px',
                    marginBottom: '16px',
                  }}
                  alt='upload'
                />
                <div style={{ textAlign: 'left' }}>
                  <Button
                    fullWidth
                    variant='outlined'
                    sx={{
                      border: '1px solid var(--primary-bg-color)',
                      borderRadius: '8px',
                      color: 'var(--primary-bg-color)',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        border: '1px solid var(--primary-bg-color)',
                      },
                    }}
                  >
                    <CachedIcon sx={{ mr: '11px' }} />
                    <label>
                      Change image
                      <br />
                      <input
                        type='file'
                        name='images'
                        onChange={(e) => onSelectFile(e)}
                        accept='image/png , image/jpeg, image/webp'
                      />
                    </label>
                  </Button>
                  <br />

                  <input type='file' />
                </div>
              </Box>
            ) : (
              <div style={{ textAlign: 'left' }}>
                <label className='collectionImage'>
                  <UploadFileOutlinedIcon
                    sx={{ width: '26px', height: '33px', marginBottom: '5px' }}
                  />
                  Add Image (png, jpg, webp)
                  <br />
                  <input
                    type='file'
                    name='images'
                    onChange={(e) => onSelectFile(e)}
                    accept='image/png, image/jpeg, image/webp'
                  />
                </label>
                <br />

                <input type='file' />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      {cid ? (
        <Button
          variant='contained'
          disableElevation
          onClick={(e) => handleUpdateCollection(e)}
          sx={{
            backgroundColor: 'var(--primary-bg-color)',
            marginLeft: '75%',
            marginTop: '25vh',
            textTransform: 'none',
            fontFamily: 'Exo',
            fontSize: '14px',
            borderRadius: '6px',
            '&:hover': { backgroundColor: '#ED5E4A' },
          }}
        >
          {/* <AddIcon sx={{ marginRight: '10px', fontSize: '14px' }} /> */}
          Update collection
        </Button>
      ) : (
        <Button
          variant='contained'
          disableElevation
          disabled={validationTest()}
          onClick={(e) => handleAddCollection(e)}
          sx={{
            backgroundColor: 'var(--primary-bg-color)',
            marginLeft: '75%',
            marginTop: '25vh',
            textTransform: 'none',
            fontFamily: 'Exo',
            fontSize: '14px',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: 'var(--primary-bg-color)',
              filter: 'brightness(1.1)',
              transition: 'all 250ms ease-in-out',
            },
          }}
        >
          <AddIcon sx={{ marginRight: '10px', fontSize: '14px' }} />
          Add collection
        </Button>
      )}
    </div>
  )
}

export default AddCollection
