import { Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import React, { useState } from 'react'
import SelectSoloThemeProduct from '../../components/SelectSoloThemeProduct/SelectSoloThemeProduct'
import { useEffect } from 'react'
import axios from '../InstanceAxios/InstanceAxios'
import jwtDecode from 'jwt-decode'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import OrangeButton from '../../components/Buttons/OrangeButton/OrangeButton'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

const SoloThemeSelectProduct = () => {
  const [products, setProducts] = useState([])
  const [soloThemeProduct, setSoloThemeProduct] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isChangeRequested, setIsChangeRequested] = useState(false)
  const [soloThemeId, setSoloThemeId] = useState(null)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const getSoloThemeProductDetails = async () => {
    try {
      const { shop_id } = jwtDecode(localStorage.getItem('token'))
      const url = `/soloTheme/getSoloTheme/${shop_id}`
      const res = await axios.get(url)
      const { id, products } = res.data
      setSoloThemeId(id)
      setSoloThemeProduct(products)
    } catch (error) {
      console.log(error)
    }
  }

  const getAvailableProducts = async () => {
    try {
      setIsLoading(true)
      const { shop_id } = jwtDecode(localStorage.getItem('token'))
      const url = `/soloTheme/getAvailableProducts/${shop_id}`
      const res = await axios.get(url)
      setProducts(res.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const saveProduct = async () => {
    try {
      let res
      if (isChangeRequested) {
        const url = `/soloTheme/changeSoloTheme/${soloThemeId}`
        res = await axios.put(url, {
          product_id: selectedProduct.product_id,
        })
      } else {
        const { shop_id } = jwtDecode(localStorage.getItem('token'))
        const url = `/soloTheme/createSoloTheme`
        res = await axios.post(url, {
          shop_id,
          product_id: selectedProduct.product_id,
        })
      }
      if (res.data.status === 'S138' || res.data.status === 'S139') {
        toast.success('Successfully saved selected product', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
      } else {
        toast.error('Failed to save selected product', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    } catch (error) {
      console.log(error)
      toast.error('Failed to save selected product', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
    } finally {
      setOpen(false)
      getSoloThemeProductDetails()
    }
  }

  const handleChangeProduct = () => {
    setIsChangeRequested(true)
    setOpen(true)
  }

  useEffect(() => {
    getSoloThemeProductDetails()
    getAvailableProducts()
  }, [])

  return (
    <Box
      sx={{
        padding: '24px',
      }}>
      <ToastContainer autoClose={5000} closeOnClick />
      <Typography
        sx={{
          color: 'var(--primary-text-color)',
          fontSize: '22px',
          textAlign: 'left',
          marginBottom: '24px',
        }}>
        Select Solo Theme Product
      </Typography>
      {soloThemeProduct && !open ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingX: '24px',
            marginY: '16px',
          }}>
          <Box sx={{ display: 'flex' }}>
            <img
              width='48px'
              style={{ marginRight: '16px' }}
              src={soloThemeProduct.product_images[0]?.image_url}
              alt='product img'
            />
            <Box
              sx={{
                textAlign: 'left',
              }}>
              <Typography
                sx={{ fontSize: '16px', color: 'var(--primary-text-color)' }}>
                {soloThemeProduct.product_name}
              </Typography>
              <Typography sx={{ fontSize: '12px', color: '#95989A' }}>
                In Stock: {soloThemeProduct.stock[0].number_of_stocks} Items
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            border: '1px solid #95989A',
            borderRadius: '8px',
            padding: '15px',
          }}
          onClick={() => setOpen(!open)}>
          <Typography
            sx={{
              color: 'var(--primary-text-color)',
              fontSize: '16px',
            }}>
            Select Product
          </Typography>
          <AddIcon
            fontSize='small'
            sx={{ color: 'var(--primary-text-color)' }}
          />
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
          marginRight: '24px',
        }}>
        <OrangeButton
          title='Change Product'
          handleOnClick={handleChangeProduct}
          sx={{
            marginRight: '16px',
          }}
          isDisabled={!soloThemeProduct}
        />
        <OrangeButton
          title='Next'
          handleOnClick={() =>
            navigate(`/settings/theme-settings/solo-theme-product-details`)
          }
          isDisabled={!soloThemeProduct}
        />
      </Box>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Select Product</DialogTitle>
        <DialogContent
          className='scroll'
          sx={{ paddingX: '0px', height: '50%' }}>
          <Box>
            {
              <SelectSoloThemeProduct
                products={products}
                selectedProduct={selectedProduct}
                handleClickedProduct={(product) => setSelectedProduct(product)}
                onClose={() => setOpen(false)}
                onSave={saveProduct}
                isLoading={isLoading}
              />
            }
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default SoloThemeSelectProduct
