import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

// import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
// import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import Profile from "../../../Images/Group 2608762.png";
import axios from "../../InstanceAxios/InstanceAxios";
import { useNavigate } from "react-router-dom";
import { getShopInfo } from "../../../utilities/StoredData";
import jwtDecode from "jwt-decode";
import { useGoogleLogout } from "react-google-login";
import { useContext } from "react";
import { AuthContext } from "../../../Contexts/AuthContext";
import { withErrorBoundary } from "react-error-boundary";

const clientId = process.env.REACT_APP_CLIENTID;

const Navbar = () => {
  const { logout } = useContext(AuthContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { getShopDetails, setNavbarData } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const goToProfile = () => {
    navigate("/profile");
    handleCloseUserMenu();
  };

  const handleLogout = () => {
    signOut();
    logout();
    handleCloseUserMenu();
  };

  const onLogoutSuccess = (res) => {};

  const onFailure = () => {};

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  const info = getShopInfo();
  const [shop, setShop] = useState({});

  const handleClickTitle = () => {
    const { shop_link } = jwtDecode(localStorage.getItem("token"));
    window.open(`https://${shop_link}.ebebsha.com/`, "_blank");
  };

  const getProductsAndCategoriesCount = async () => {
    try {
      const url = `/merchants/getProductsAndCategoriesCount/${info.shop_id}`;
      const res = await axios.get(url);
      setNavbarData({
        totalProducts: res.data.totalProducts,
        totalCategories: res.data.totalCategories,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const url = `/merchants/merchantV2/${info.merchant_id}`;
  useEffect(() => {
    getShopDetails();
    const getData = async () => {
      const res = await axios.get(url);
      setShop(res.data);
    };
    getData();
    getProductsAndCategoriesCount();
  }, []);

  return (
    <>
      {/* Avater */}
      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            padding: "20px 32px 20px 37px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <KeyboardDoubleArrowLeftIcon sx={{ paddingRight: '21px', color: 'var(--primary-text-color)', width: '27px', height: '41px' }} /> */}

            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: '14px', ml: '150px', display: { xs: 'none', md: 'flex', } }}> */}
            <IconButton
              sx={{
                p: 0,
                mr: "16px",
                border: "1px solid var(--primary-text-color)",
                display: { xs: "none", md: "flex" },
              }}
            >
              {shop &&
                shop?.shops &&
                (shop?.shops[0]?.shop_image ? (
                  <Avatar alt="Shop logo" src={shop?.shops[0]?.shop_image} />
                ) : (
                  <Avatar alt="Shop logo" src={shop?.shop_image} />
                ))}
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              onClick={handleClickTitle}
              sx={{
                mr: 2,
                cursor: "pointer",
                fontSize: "22px",
                color: "var(--primary-text-color)",
              }}
            >
              {info.shop_name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ padding: "0px" }}>
              {shop?.img_url ? (
                <img width="32px" src={shop?.img_url} alt="profile" />
              ) : (
                <img width="32px" src={Profile} alt="profile" />
              )}
            </IconButton>
            <Menu
              sx={{ mt: "35px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={goToProfile}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withErrorBoundary(Navbar, {
  fallbackRender: (error) => (
    <Box sx={{ color: (theme) => theme.palette.error.main }}>
      {JSON.stringify(error)}
    </Box>
  ),
});
