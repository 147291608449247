import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../Contexts/AuthContext'
import { NotificationContext } from '../../Contexts/NotificationContext'
import './Plugins.css'

const Plugin = (props) => {
  const { pluginsStatus } = useContext(AuthContext)
  const { showNotification } = useContext(NotificationContext)
  const navigate = useNavigate()

  const handleNavigate = (data) => {
    if (pluginsStatus.subscription_status[props?.plugin?.pluginAccessName]) {
      navigate(props?.plugin?.link)
    } else {
      showNotification(
        'error',
        `Your current plan doesn't have access to ${props?.plugin?.title}. Please Upgrade`
      )
      navigate(`/settings/subscription`)
    }
  }
  return (
    <Grid item md={4}>
      <Box className='plugin-container'>
        <div className='plugin-header'>
          <img src={props.plugin.image} height='60px' width='60px' alt='' />
          <span>{props.plugin.title}</span>
        </div>
        <div className='plugin-desc'>{props.plugin.description}</div>
        {Object.keys(pluginsStatus).length > 0 && (
          <Button
            variant='contained'
            disableElevation
            sx={{
              width: '30%',
              backgroundColor: 'var(--primary-bg-color)',
              fontFamily: 'Exo',
              textTransform: 'none',
              borderRadius: '6px',
              '&:hover': {
                // borderColor: 'var(--primary-bg-color)',
                backgroundColor: 'var(--primary-bg-color)',
                filter: 'brightness(1.15)',
              },
            }}
            onClick={() => handleNavigate(props.plugin)}
          >
            {pluginsStatus.install_status[props?.plugin?.pluginAccessName]
              ? 'Setting'
              : 'Install'}
          </Button>
        )}
      </Box>
    </Grid>
  )
}

export default Plugin
