import React from 'react'
import './DeliverySettings.css'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

const DeliverySettingsHome = (props) => {
  const navigate = useNavigate()

  return (
    <div className='delivery-bg'>
      <div className='single-delivery delivery-section'>
        <div>
          <p className='delivery-title'>Delivery Settings</p>
          <p className='delivery-info'>Manage your own delivery method</p>
        </div>
        <div>
          <Button
            sx={{
              backgroundColor: 'var(--primary-bg-color)',
              border: '1px solid var(--primary-bg-color)',
              '&:hover': {
                backgroundColor: 'var(--primary-bg-color)',
                border: '1px solid var(--primary-bg-color)',
                filter: 'brightness(1.1)',
              },
            }}
            className='set-up-btn'
            onClick={() => {
              navigate('/settings/subscription')
            }}
          >
            Set Up
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeliverySettingsHome
