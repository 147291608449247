import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../../InstanceAxios/InstanceAxios'
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CustomerInfo from '../CustomerInfo/CustomerInfo'
import UpdateStatusDialog from '../UpdateStatusDialog/UpdateStatusDialog'
import { AuthContext } from '../../../Contexts/AuthContext'

const OrderDetail = () => {
  const { oid } = useParams()
  const [details, setDetails] = useState({})
  const [status, setStatus] = useState(0)
  const [render, setRender] = useState(0)
  var moment = require('moment')
  const { shopDetails } = useContext(AuthContext)
  const navigate = useNavigate()

  const url = `/sales/getSingleSaleV2/${oid}`
  const urlAccept = `/sales/acceptOrder/${oid}`
  const urlCancel = `/sales/updateOrderStatus/${oid}`

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(url)
      setDetails(res.data)
      setStatus(details?.order_status)
    }
    fetchData()
  }, [url, details?.order_status, render])

  const onClickAccept = async (e) => {
    const res = await axios.put(urlAccept, {
      delivery_partner: details.delivery_partner,
    })
    setRender(1)
  }
  const onClickCancel = async (e) => {
    const res = await axios.put(urlCancel, {
      order_status: 7,
    })
    setRender(2)
  }

  const time = moment(details.datetime).utc().format('YYYY-MM-DD hh:mm A')

  const updateStatus = (value) => {
    setStatus(value)
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <div style={{ marginLeft: '28px', marginRight: '28px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '22px',
        }}
      >
        <Typography
          sx={{
            color: 'var(--primary-text-color)',
            fontSize: '22px',
            textAlign: 'left',
            fontFamily: 'Exo',
          }}
        >
          Order Id #{details.serial}
        </Typography>
        {details.order_status === 1 && (
          <Box>
            <Button
              disableElevation
              onClick={onClickAccept}
              variant='contained'
              sx={{
                marginRight: '8px',
                fontFamily: 'Exo',
                fontWeight: 500,
                fontSize: '16px',
                width: '142px',
                height: '40px',
                backgroundColor: '#00DD64',
                borderRadius: '6px',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#00B552' },
              }}
            >
              Accept
            </Button>
            <Button
              onClick={onClickCancel}
              variant='outlined'
              sx={{
                width: '142px',
                height: '40px',
                color: '#ED1C24',
                fontFamily: 'Exo',
                fontWeight: 500,
                fontSize: '16px',
                border: '1px solid #ED1C24',
                borderRadius: '6px',
                textTransform: 'none',
                '&:hover': {
                  border: '1px solid #ED1C24',
                  backgroundColor: '#E7D2D3',
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>

      <Grid container sx={{ marginTop: '24px', paddingBottom: '0px' }}>
        <Grid
          item
          md={6}
          className='scroll'
          sx={{ overflowY: 'auto', height: '75.5vh' }}
        >
          <Container sx={{ backgroundColor: '#FFFFFF', paddingTop: '24px' }}>
            <Grid container>
              <Grid item md={3} sx={{ color: 'var(--primary-text-color)' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    fontFamily: 'Exo',
                  }}
                >
                  Product Description
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontFamily: 'Exo',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  Qty
                </Typography>
              </Grid>
              <Grid item md={3} sx={{ textAlign: 'right' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontFamily: 'Exo',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  Amount
                </Typography>
              </Grid>
              <Grid item md={3} sx={{ textAlign: 'right' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontFamily: 'Exo',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  Total Amount
                </Typography>
              </Grid>
            </Grid>{' '}
            <hr style={{ border: '1px solid #e6e6e6', margin: '16px 0' }} />
            {details?.ordered_items !== undefined &&
              details?.ordered_items.map((detail, index) => (
                <Grid
                  key={index}
                  container
                  sx={{
                    marginBottom:
                      index + 1 !== details?.ordered_items.length ? '16px' : '',
                  }}
                  onClick={() => navigate(`/inventory/${detail.product_id}`)}
                >
                  <Grid item md={3} sx={{ color: 'var(--primary-text-color)' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: 'Exo',
                        fontWeight: 500,
                        textAlign: 'left',
                      }}
                    >
                      {index + 1}.{detail.product_name}
                    </Typography>
                    {detail?.variant_products &&
                      detail?.variant_products.variant_value_variant_product.map(
                        (v) => (
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontFamily: 'Exo',
                              fontWeight: 500,
                              textAlign: 'left',
                              color: '#95989A',
                            }}
                          >
                            {capitalizeFirstLetter(
                              v?.variant_values.variant_names.title
                            )}
                            : {v?.variant_values.title}
                          </Typography>
                        )
                      )}
                  </Grid>
                  <Grid item md={3}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: 'Exo',
                        fontWeight: 500,
                        color: 'var(--primary-text-color)',
                      }}
                    >
                      {detail.quantity}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sx={{ textAlign: 'right' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: 'Exo',
                        fontWeight: 500,
                        color: 'var(--primary-text-color)',
                      }}
                    >
                      {shopDetails?.currencies?.title}{' '}
                      {detail.unit_selling_price}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sx={{ textAlign: 'right' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: 'Exo',
                        fontWeight: 500,
                        color: 'var(--primary-text-color)',
                      }}
                    >
                      {shopDetails?.currencies?.title}{' '}
                      {detail.unit_selling_price * detail.quantity}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            <hr style={{ border: '0.5px solid #E6E6E6', margin: '16px 0' }} />
            <Grid
              container
              sx={{
                marginBottom: '16px',
              }}
            >
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  Sub-total
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  {shopDetails?.currencies?.title} {details.sub_total}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  Delivery Charge
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  {shopDetails?.currencies?.title} {details.delivery_charge}
                </Typography>
              </Grid>
            </Grid>
            <hr style={{ border: '0.5px solid #e6e6e6', margin: '16px 0' }} />
            <Grid
              container
              sx={{
                marginBottom: '32px',
              }}
            >
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  Total
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  {shopDetails?.currencies?.title} {details.total_amount}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                marginBottom: '16px',
              }}
            >
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  Paid
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  {shopDetails?.currencies?.title} {details.received_amount}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  Due
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                    color: 'var(--primary-text-color)',
                  }}
                >
                  {shopDetails?.currencies?.title} {details.due_amount}
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <Box>
            <CustomerInfo customerId={details.customer_id} />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            padding: '0 24px 0 48px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              padding: '24px',
              fontFamily: 'Exo',
              color: 'var(--primary-text-color)',
            }}
          >
            {status === 1 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'Exo',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <PendingActionsOutlinedIcon sx={{ marginRight: '9px' }} />
                  <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                    Pending
                  </Typography>
                </Box>
                <Button
                  // onClick={handleClickOpen}
                  disabled
                  variant='contained'
                  sx={{
                    marginRight: '8px',
                    fontFamily: 'Exo',
                    width: '145px',
                    fontSize: '16px',
                    height: '36px',
                    borderRadius: '6px',
                    backgroundColor: 'var(--primary-bg-color)',
                    textTransform: 'none',
                    '&:hover': {
                      border: '1px solid var(--primary-bg-color)',
                      backgroundColor: 'var(--primary-bg-color)',
                    },
                  }}
                >
                  Update Status
                </Button>
              </Box>
            )}
            {status === 2 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'Exo',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CheckCircleOutlineIcon sx={{ marginRight: '9px' }} />
                  <Typography sx={{ fontSize: '16px' }}>Accepted</Typography>
                </Box>
                <UpdateStatusDialog
                  oid={oid}
                  status={details?.order_status}
                  setStatus={updateStatus}
                />
              </Box>
            )}
            {status === 3 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'Exo',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CheckCircleOutlineIcon sx={{ marginRight: '9px' }} />
                  <Typography sx={{ fontSize: '16px' }}>Picked up</Typography>
                </Box>
                <UpdateStatusDialog
                  oid={oid}
                  status={details?.order_status}
                  setStatus={updateStatus}
                />
              </Box>
            )}
            {status === 4 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'Exo',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CheckCircleOutlineIcon sx={{ marginRight: '9px' }} />
                  <Typography sx={{ fontSize: '16px' }}>In Transit</Typography>
                </Box>
                <UpdateStatusDialog
                  oid={oid}
                  status={details?.order_status}
                  setStatus={updateStatus}
                />
              </Box>
            )}
            {status === 5 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'Exo',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CheckCircleOutlineIcon sx={{ marginRight: '9px' }} />
                  <Typography sx={{ fontSize: '16px' }}>Delivered</Typography>
                </Box>
                <UpdateStatusDialog
                  oid={oid}
                  status={details?.order_status}
                  setStatus={setStatus}
                />
              </Box>
            )}
            {status === 6 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'Exo',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CheckCircleOutlineIcon sx={{ marginRight: '9px' }} />
                  <Typography sx={{ fontSize: '16px' }}>Returned</Typography>
                </Box>
                <UpdateStatusDialog
                  oid={oid}
                  status={details?.order_status}
                  setStatus={setStatus}
                />
              </Box>
            )}
            {status === 7 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'Exo',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CheckCircleOutlineIcon sx={{ marginRight: '9px' }} />
                  <Typography sx={{ fontSize: '16px' }}>Cancelled</Typography>
                </Box>
                <UpdateStatusDialog
                  oid={oid}
                  status={details?.order_status}
                  setStatus={setStatus}
                />
              </Box>
            )}
            <hr style={{ border: '1px solid #e6e6e6', margin: '16px 0' }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                Order Created
              </Typography>
              <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                {time}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                Delivery Method
              </Typography>
              {details.delivery_partner === 'd0' && (
                <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                  Self Delivery
                </Typography>
              )}
              {details.delivery_partner === 'd1' && (
                <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                  Paperfly
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                fontFamily: 'Exo',
                marginBottom: '16px',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                Payment Method
              </Typography>
              <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                {details.payment_method}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontFamily: 'Exo' }}>
                Payment Status
              </Typography>
              {details.due_amount === '0' ? (
                <Typography
                  sx={{
                    color: ' #00DD64',
                    fontFamily: 'Exo',
                    fontSize: '16px',
                  }}
                >
                  PAID
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: ' #ED1C24',
                    fontSize: '16px',
                    fontFamily: 'Exo',
                  }}
                >
                  UNPAID
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default OrderDetail
