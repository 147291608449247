import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { AuthContext } from '../../../Contexts/AuthContext'
import { useEffect } from 'react'

const SideNavList = ({
  item,
  index,
  selectedItem,
  handleChangeSelectedItem,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { navbarData } = useContext(AuthContext)
  const toggle = () => setIsOpen(!isOpen)

  const SideNavItemBox =
    index === selectedItem
      ? styled(Box)(({ theme }) => ({
          display: 'flex',
          paddingX: '35px',
          backgroundColor: 'rgba(208, 188, 255, 0.08)',
          borderRadius: '8px',
          border: '2px solid var(--primary-text-color)',
        }))
      : styled(Box)(({ theme }) => ({
          display: 'flex',
          paddingX: '35px',
          border: '2px solid var(--primary-text-color)',
          '&:hover': {
            backgroundColor: 'rgba(208, 188, 255, 0.08)',
            borderRadius: '8px',
          },
        }))

  useEffect(() => {
    if (window.location.href.includes('inventory')) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [window.location.href])

  return (
    <div>
      {item.subNav ? (
        <Box onClick={() => handleChangeSelectedItem(index)}>
          <SideNavItemBox
            sx={{ paddingLeft: '35px', paddingY: '14px', marginX: '8px' }}
            onClick={toggle}
          >
            {index === selectedItem ? item.selectedIcon : item.icon}
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: '18px',
                marginLeft: '20px',
                cursor: 'pointer',
              }}
            >
              {item.title}
            </Typography>
          </SideNavItemBox>
          <Box
            sx={{
              marginBottom: '5px',
              paddingLeft: '105px',
            }}
          >
            {isOpen &&
              item?.subNav.map((child, index) => (
                <Link
                  to={child.path}
                  style={{
                    textDecoration: 'none',
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    key={index}
                    sx={{
                      fontSize: '16px',
                      color:
                        window.location.href.split('/')[4] ===
                        child.title.toLowerCase()
                          ? 'white'
                          : '#79747E',
                      marginBottom:
                        index === item.subNav.length - 1 ? '0px' : '8px',
                    }}
                  >
                    {child.title} ({navbarData[child.dataAccess]})
                  </Typography>
                </Link>
              ))}
          </Box>
        </Box>
      ) : (
        <Link
          to={item.path}
          style={{ textDecoration: 'none' }}
          onClick={() => handleChangeSelectedItem(index)}
        >
          <SideNavItemBox
            sx={{
              display: 'flex',
              paddingX: '35px',
              paddingY: '14px',
              marginX: '8px',
            }}
          >
            {index === selectedItem ? item.selectedIcon : item.icon}
            <Typography
              sx={{ color: '#FFFFFF', fontSize: '18px', marginLeft: '20px' }}
            >
              {item.title}
            </Typography>
          </SideNavItemBox>
        </Link>
      )}
    </div>
  )
}

export default SideNavList
