import MuiCircularProgress from '@mui/material/CircularProgress'

const CircularProgress = (props) => {
  return (
    <MuiCircularProgress
      {...props}
      sx={{ color: 'var(--primary-bg-color)', ...props.sx }}
    />
  )
}

export default CircularProgress
