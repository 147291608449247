import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getShopInfo } from '../../../utilities/StoredData'
import axios from '../../InstanceAxios/InstanceAxios'

const Customers = () => {
  const [customers, setCustomers] = useState([])

  const navigate = useNavigate()
  const info = getShopInfo()

  useEffect(() => {
    const url = `/customers/allCustomers/${info.shop_id}`
    const getData = async () => {
      const res = await axios.get(url)
      setCustomers(res.data)
    }
    getData()
  }, [info.shop_id])

  const rows = customers

  const columns = [
    { field: 'customer_name', headerName: 'Customer', width: 300 },
    { field: 'customer_district', headerName: 'District', width: 246 },

    {
      field: 'customer_phone_number',
      headerName: 'Contact Number',
      width: 246,
    },
    {
      field: 'customer_email',
      headerName: 'Email',
      sortable: false,
      width: 246,
    },
    // {
    //     field: 'action',
    //     headerName: '',
    //     sortable: false,
    //     width: 210,
    //     renderCell: (params) => {
    //         // const urlAccept = `/sales/acceptOrder/${params.row.sales_id}`

    //         // const onClickAccept = async e => {
    //         //     e.stopPropagation();
    //         //     console.log(params.row.delivery_partner);
    //         //     const res = await axios.put(urlAccept,
    //         //         {
    //         //             "delivery_partner": params.row.delivery_partner
    //         //         })
    //         //     console.log(res.data);
    //         //     setSelected(1)
    //         //     setRender(params.row.sales_id)

    //         // }

    //         return <>

    //             <DeleteOutlineIcon />
    //         </>
    //     }

    // },
  ]

  const handleRowClick = (params) => {
    // console.log(params.row.serial);
    navigate(`/customer/${params.row.customer_id}`)
  }
  return (
    <DataGrid
      getRowId={(row) => row.customer_id}
      rowHeight={80}
      rows={rows}
      columns={columns}
      pageSize={6}
      rowsPerPageOptions={[4]}
      sx={{
        backgroundColor: '#FFFFFF',
        fontFamily: 'Exo',
        fontWeight: 500,
        color: 'var(--primary-text-color)',
        marginX: '27px',
        fontSize: '16px',
        border: 'none',
      }}
      onRowClick={handleRowClick}
      // checkboxSelection
    />
  )
}

export default Customers
