import React from 'react'
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import OrangeButton from '../Buttons/OrangeButton/OrangeButton'
import CircularProgress from '../CircularProgress'

const SelectCampaignCategories = ({
  categories,
  selectedCategories,
  handleClickedCategory,
  onClose,
}) => {
  return (
    <div>
      {categories.length ? (
        categories.map((category, index) => (
          <Box
            key={index}
            onClick={() => handleClickedCategory(category.category_id)}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingX: '24px',
                marginY: '16px',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <img
                  width='48px'
                  style={{ marginRight: '16px' }}
                  src={category.img_url}
                  alt='category img'
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: 'var(--primary-text-color)',
                    }}
                  >
                    {category.category}
                  </Typography>
                </Box>
              </Box>
              {categories.length ? (
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  name='payment_mode'
                  value={selectedCategories.includes(category.category_id)}
                >
                  <FormControlLabel value={true} control={<Radio />} />
                </RadioGroup>
              ) : (
                <></>
              )}
            </Box>
            {categories.length - 1 !== index ? (
              <hr style={{ border: '2px solid #F2F2F2', margin: '0 20px' }} />
            ) : (
              <></>
            )}
          </Box>
        ))
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          margin: '24px 24px 0 24px',
        }}
      >
        <OrangeButton
          title='Save'
          handleOnClick={onClose}
          sx={{
            width: '100%',
            backgroundColor: 'var(--primary-bg-color)',
            '&:hover': {
              backgroundColor: 'var(--primary-bg-color)',
            },
          }}
        />
      </Box>
    </div>
  )
}

export default SelectCampaignCategories
