import { Button } from '@mui/material'
import React from 'react'

const OrangeButton = ({
  title,
  handleOnClick,
  sx,
  icon,
  isChip = false,
  isDisabled = false,
}) => {
  return (
    <Button
      variant='contained'
      disableElevation
      disabled={isDisabled}
      sx={{
        backgroundColor: 'var(--primary-bg-color)',
        color: '#FFFFFF',
        borderRadius: '6px',
        padding: '8px 16px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        fontFamily: 'Exo',
        textTransform: 'none',
        border: isDisabled
          ? '1px solid #F8F8F8'
          : '1px solid var(--primary-bg-color)',
        '&:hover': {
          filter: 'brightness(1.1)',
          backgroundColor: 'var(--primary-bg-color)',
          color: '#FFFFFF',
        },
        ...sx,
      }}
      onClick={handleOnClick}
    >
      {icon}
      {title}
    </Button>
  )
}

export default OrangeButton
