import { Button, Grid } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'

import { useContext } from 'react'
import { AuthContext } from '../../Contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

const DomainSettingsHome = (props) => {
  const { shopDetails, subscribedPlan } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleAddDomain = () => {
    if (subscribedPlan.custom_domain) {
      props.changeComponent('next')
    } else {
      navigate('/settings/subscription')
    }
  }

  return (
    <>
      <div className='domain-home-continer'>
        <Grid
          container
          sx={{ textAlign: 'left' }}
          className='domain-home-header'>
          <Grid item md={6}>
            Domain Name
          </Grid>
          <Grid item md={5}>
            Status
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
        {shopDetails?.shop_domain ? (
          <div style={{ margin: '16px 0' }}>
            <Grid
              container
              alignItems='center'
              sx={{ textAlign: 'left' }}
              className='domain-home-data'>
              <Grid item md={6}>
                {shopDetails?.shop_domain}
              </Grid>
              <Grid item md={5}>
                <span className='domain-home-chip'>Connected</span>
              </Grid>
              <Grid item md={1}>
                {shopDetails?.shop_domain ? (
                  <Button
                    variant='contained'
                    disableElevation
                    sx={{
                      backgroundColor: 'var(--primary-bg-color)',
                      textTransform: 'none',
                      '&:hover': {
                        // borderColor: 'var(--primary-bg-color)',
                        backgroundColor: 'var(--primary-bg-color)',
                        filter: 'brightness(1.1)',
                      },
                    }}
                    onClick={() => props.changeComponent('next')}>
                    Edit
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </div>
        ) : (
          <></>
        )}
      </div>
      {shopDetails?.shop_domain ? (
        <></>
      ) : (
        <Grid container className='domain-home-btn-container'>
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <Button
              disableElevation
              variant='contained'
              sx={{
                backgroundColor: 'var(--primary-bg-color)',
                '&:hover': {
                  borderColor: 'var(--primary-bg-color)',
                  backgroundColor: 'var(--primary-bg-color)',
                },
              }}
              onClick={handleAddDomain}>
              <AddIcon fontSize='small' sx={{ marginRight: '10px' }} />
              Add Exiting Domain
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default DomainSettingsHome
