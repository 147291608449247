import { Box, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined'
import axios from '../../InstanceAxios/InstanceAxios'

const CustomerInfo = ({ customerId }) => {
  const [info, setInfo] = useState({})

  const url = `/customers/getSingleCustomer/${customerId}`
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(url)
      setInfo(res.data)
    }
    fetchData()
  }, [url])
  return (
    <Box
      sx={{
        marginTop: '24px',
        backgroundColor: '#FFFFFF',
        paddingX: '24px',
        paddingY: '24px',
        color: 'var(--primary-text-color)',
      }}>
      <Typography
        sx={{ color: '#79747E', textAlign: 'left', fontFamily: 'Exo' }}>
        Customer Information
      </Typography>
      <hr style={{ border: '0.5px solid #e6e6e6', margin: '16px 0' }} />
      <Box sx={{ display: 'flex', fontFamily: 'Exo', marginBottom: '16px' }}>
        <PersonOutlineOutlinedIcon sx={{ marginRight: '20px' }} />
        <Typography>{info.customer_name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', fontFamily: 'Exo', marginBottom: '16px' }}>
        <LocalPhoneOutlinedIcon sx={{ marginRight: '20px' }} />
        <Typography>+880{info.customer_phone_number}</Typography>
      </Box>
      <Box sx={{ display: 'flex', fontFamily: 'Exo', marginBottom: '16px' }}>
        <RoomOutlinedIcon sx={{ marginRight: '20px' }} />
        <Typography>{info.customer_address}</Typography>
      </Box>
      {/* <Box sx={{ display: 'flex', fontFamily: 'Exo' }}>
                <CardGiftcardOutlinedIcon sx={{ marginRight: '20px' }} />
                <Typography>fdf</Typography>
            </Box> */}
    </Box>
  )
}

export default CustomerInfo
