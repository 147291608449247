import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from '../InstanceAxios/InstanceAxios'
import AddIcon from '@mui/icons-material/Add'
import jwtDecode from 'jwt-decode'
import DeliverySettingsCharge from './DeliverySettingsCharge'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--primary-text-color)',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'var(--primary-text-color)',
    },
  },
})

const CssInputLabel = styled(Select)({
  '& label.Mui-focused': {
    color: 'var(--primary-text-color)',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'var(--primary-text-color)',
  },
})

const DeliverySettingsPickUpAddress = (props) => {
  const [data, setData] = useState({
    address: '',
    division: '',
    district: '',
    thana: '',
  })
  const [apiData, setApiData] = useState({
    address: '',
    division: '',
    district: '',
    thana: '',
  })
  const [divisions, setDivisions] = useState([])
  const [districts, setDistricts] = useState([])
  const [thanas, setThanas] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)
  const [isReadOnly, setIsReadOnly] = useState(true)

  const handleSubmit = async () => {
    const decoded = jwtDecode(localStorage.getItem('token'))
    const url = `/merchantPickup/addMerchantPickup`
    setIsDisabled(true)
    try {
      const resposne = await axios.post(url, {
        ...data,
        shop_id: decoded.shop_id,
      })
      if (resposne.data.status === 'S129') {
        toast.success('Pickup address updated', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
        setIsReadOnly(false)
      }
    } catch (error) {
      console.log(error)
      toast.error('Pickup address failed to update.', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
    }
  }

  const handleChange = (event) => {
    const oldData = { ...data }
    oldData[event.target.name] = event.target.value
    setData({ ...oldData })
  }

  const getDivisions = async () => {
    try {
      const url = `/locations/getDivisions`
      const res = await axios.get(url)
      setDivisions(res.data.divisions)
    } catch (error) {
      console.log(error)
    }
  }

  const getDistrictsByDivision = async () => {
    try {
      const url = `/locations/getDistrictsByDisivion/${data.division.toUpperCase()}`
      const res = await axios.get(url)
      setDistricts(res.data.districts)
    } catch (error) {
      console.log(error)
    }
  }

  const getThanas = async () => {
    try {
      const url = `/locations/getThanas`
      const res = await axios.get(url)
      const arrayThana = res.data.thanas.map((tn) => tn.title)
      setThanas([...arrayThana])
    } catch (error) {
      console.log(error)
    }
  }

  const getPickupDetails = async () => {
    try {
      const decoded = jwtDecode(localStorage.getItem('token'))
      const url = `/merchantPickup/getMerchantPickupDetails/${decoded.shop_id}`
      const res = await axios.get(url)
      if (res.data) {
        if (res.data.address.length) {
          setIsReadOnly(false)
        }
        setApiData({
          address: res.data.address,
          division: res.data.division,
          district: res.data.district,
          thana: res.data.thana,
        })
        setData({
          ...data,
          address: res.data.address,
          division: res.data.division,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDivisions()
    getThanas()
    getPickupDetails()
  }, [])

  useEffect(() => {
    if (data.division.length) getDistrictsByDivision()
  }, [data.division])

  useEffect(() => {
    if (apiData.district) {
      setData({
        ...data,
        district: apiData.district,
      })
    }
  }, [districts])

  useEffect(() => {
    if (apiData.thana.length && thanas.length) {
      const index = thanas.findIndex((thana) => thana == apiData.thana)
      setData({
        ...data,
        thana: thanas[index],
      })
    }
  }, [thanas])

  useEffect(() => {
    if (
      data.address.length &&
      data.division.length &&
      data.district.length &&
      data.thana.length
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [data.address, data.division, data.district, data.thana])

  return (
    <div className='pickup-adrs-div'>
      <ToastContainer autoClose={5000} closeOnClick />
      <div className='pickup-adrs-form'>
        <p className='delivery-title'>Set Pickup Address</p>
        <CssTextField
          sx={{ margin: '10px 0' }}
          onChange={(e) => handleChange(e)}
          value={data.address}
          name='address'
          id='outlined-basic'
          label='Pickup Address*'
          placeholder='Enter street/road no, area'
          variant='outlined'
          fullWidth
        />
        <FormControl fullWidth sx={{ margin: '10px 0' }}>
          <InputLabel
            id='demo-simple-select-label'
            sx={{
              '&.Mui-focused': {
                color: 'var(--primary-text-color)',
              },
            }}
          >
            Division*
          </InputLabel>
          <CssInputLabel
            label='Division*'
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            name='division'
            value={data.division}
            onChange={(e) => handleChange(e)}
          >
            {divisions.map((division, i) => (
              <MenuItem key={i} value={division}>
                {division}
              </MenuItem>
            ))}
          </CssInputLabel>
        </FormControl>
        <FormControl fullWidth sx={{ margin: '10px 0' }}>
          <InputLabel
            id='demo-simple-select-label'
            sx={{
              '&.Mui-focused': {
                color: 'var(--primary-text-color)',
              },
            }}
          >
            District*
          </InputLabel>
          <CssInputLabel
            label='District*'
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            name='district'
            value={data.district}
            onChange={(e) => handleChange(e)}
          >
            {districts.map((district, i) => (
              <MenuItem key={i} value={district}>
                {district}
              </MenuItem>
            ))}
          </CssInputLabel>
        </FormControl>
        <Autocomplete
          disablePortal
          id='combo-box-demo'
          defaultValue={data.thana}
          value={data.thana}
          options={thanas}
          sx={{ margin: '10px 0' }}
          onChange={(event, newValue) => setData({ ...data, thana: newValue })}
          renderInput={(params) => <CssTextField {...params} label='Thana*' />}
        />
        <div className='place-right'>
          <Button
            disableElevation
            variant='contained'
            sx={{
              backgroundColor: 'var(--primary-bg-color)',
              fontFamily: 'Exo',
              textTransform: 'none',
              '&:hover': {
                // borderColor: '# ED5E4A',
                filter: 'brightness(1.15)',
              },
            }}
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            {!apiData.address.length ? (
              <AddIcon fontSize='small' sx={{ marginRight: '10px' }} />
            ) : (
              <></>
            )}
            {apiData.address.length ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
      <DeliverySettingsCharge isReadOnly={isReadOnly} />
    </div>
  )
}

export default DeliverySettingsPickUpAddress
