import React, { useEffect, useState } from 'react'
import WhatsappLogo from '../../Images/whastapp_logo.png'
import jwtDecode from 'jwt-decode'
import { Button } from '@mui/material'
import axios from '../InstanceAxios/InstanceAxios'
import WhatsappDialougue from './WhatsappDialougue'
import WhatsappFeature from './WhatsappFeature'
import WhatsappInstructions from './WhatsappInstructions'

const selected = {
  padding: '8px 16px',
  backgroundColor: 'var(--primary-bg-color)',
  border: '1px solid var(--primary-bg-color)',
  borderRadius: '6px',
  textTransform: 'none',
  fontFamily: 'Exo',
  '&:hover': {
    border: '1px solid var(--primary-bg-color)',
    borderColor: 'var(--primary-bg-color)',
    backgroundColor: 'var(--primary-bg-color)',
  },
}
const notSelected = {
  padding: '8px 16px',
  backgroundColor: 'white',
  border: '1px solid var(--primary-text-color)',
  borderRadius: '6px',
  color: 'var(--primary-text-color)',
  textTransform: 'none',
  fontFamily: 'Exo',
  '&:hover': {
    border: '1px solid var(--primary-text-color)',
    backgroundColor: 'white',
    color: 'var(--primary-text-color)',
  },
}

const PluginWhatsapp = () => {
  const { shop_id } = jwtDecode(localStorage.getItem('token'))
  const [isShowDetails, setIsShowDetails] = useState(true)
  const [isOpenedDialouge, setIsOpenedDialouge] = useState(false)
  const [data, setData] = useState({})

  const handleInstall = () => {
    setIsOpenedDialouge(true)
  }

  const handleUninstall = async () => {
    try {
      const updatedData = {}
      for (let key in data) {
        updatedData[key] = null
      }
      const res = await axios.put(
        `/merchants/updateWhatsappNumber/${shop_id}`,
        updatedData
      )
      getData()
    } catch (error) {
      console.log(error)
    }
  }

  const getData = async () => {
    try {
      const res = await axios.get(`/merchants/getWhatsappNumber/${shop_id}`)
      setData(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!isOpenedDialouge) {
      getData()
    }
  }, [isOpenedDialouge])

  return (
    <div
      className='single-plugin-container scroll'
      style={{ overflowY: 'auto', height: '70vh' }}
    >
      <WhatsappDialougue
        isOpenedDialouge={isOpenedDialouge}
        setIsOpenedDialouge={setIsOpenedDialouge}
        data={data}
      />
      <div className='single-plugin-header'>
        <div>
          <img src={WhatsappLogo} height='120px' width='120px' alt='' />
        </div>
        <div className='single-plugin-header-right'>
          <p className='single-plugin-title'>Whatsapp</p>
          {Object.keys(data).length == 0 || Object.values(data)[0] === null ? (
            <Button
              disableElevation='false'
              variant='contained'
              sx={{
                padding: '8px 16px',
                backgroundColor: 'var(--primary-bg-color)',
                borderRadius: '6px',
                textTransform: 'none',
                fontFamily: 'Exo',
                '&:hover': {
                  backgroundColor: 'var(--primary-bg-color)',
                  filter: 'brightness(1.1)',
                },
              }}
              onClick={handleInstall}
            >
              Install
            </Button>
          ) : (
            <span>
              <Button
                disableElevation='false'
                variant='contained'
                sx={{
                  padding: '8px 16px',
                  marginRight: '16px',
                  backgroundColor: 'var(--primary-bg-color)',
                  borderRadius: '6px',
                  textTransform: 'none',
                  fontFamily: 'Exo',
                  '&:hover': {
                    backgroundColor: 'var(--primary-bg-color)',
                    filter: 'brightness(1.1)',
                  },
                }}
                onClick={handleInstall}
              >
                Setting
              </Button>
              <Button
                disableElevation='false'
                variant='outlined'
                sx={{
                  padding: '8px 16px',
                  color: 'var(--primary-bg-color)',
                  border: '1px solid var(--primary-bg-color)',
                  borderRadius: '6px',
                  textTransform: 'none',
                  fontFamily: 'Exo',
                  '&:hover': {
                    border: '1px solid var(--primary-bg-color)',
                    color: 'var(--primary-bg-color)',
                    backgroundColor: 'var(--primary-bg-color)',
                  },
                }}
                onClick={handleUninstall}
              >
                Uninstall
              </Button>
            </span>
          )}
        </div>
      </div>
      <div className='single-plugin-btn-div'>
        <Button
          disableElevation='false'
          variant='contained'
          sx={isShowDetails ? selected : notSelected}
          onClick={() => setIsShowDetails(true)}
        >
          Details
        </Button>
        <Button
          variant='contained'
          disableElevation='false'
          sx={
            isShowDetails
              ? { marginLeft: '24px', ...notSelected }
              : { marginLeft: '24px', ...selected }
          }
          onClick={() => setIsShowDetails(false)}
        >
          Instruction
        </Button>
      </div>
      <div className='single-plugin-description'>
        {isShowDetails ? <WhatsappFeature /> : <WhatsappInstructions />}
      </div>
    </div>
  )
}

export default PluginWhatsapp
