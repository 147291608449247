import React from 'react'
import PixelLogo from '../../Images/pixel_logo.png'
import AnalyticsLogo from '../../Images/analytics_logo.png'
import MessengerLogo from '../../Images/msngr_logo.png'
import SslLogo from '../../Images/ssl.png'
import StripeLogo from '../../Images/stripe_logo.jpeg'
import WhatsappLogo from '../../Images/whastapp_logo.png'
import Plugin from './Plugin'
import { Grid } from '@mui/material'
import './Plugins.css'

const Plugins = () => {
  const data = [
    {
      id: 1,
      title: 'FACEBOOK PIXEL',
      description:
        'Track, measure and optimize to retarget audiences using the Facebook Pixel.',
      image: PixelLogo,
      link: '/plugins/pixel',
      pluginAccessName: 'facebook_pixel_id',
    },
    {
      id: 2,
      title: 'GOOGLE ANALYTICS',
      description: 'Measure your website performance in real time.',
      image: AnalyticsLogo,
      link: '/plugins/analytics',
      pluginAccessName: 'google_analytics_measurement_id',
    },
    {
      id: 3,
      title: 'MESSENGER CHAT BUBBLE',
      description:
        'Use messenger chat plugin on your website to help customers reach you for supports.',
      image: MessengerLogo,
      link: '/plugins/messenger',
      pluginAccessName: 'facebook_page_id',
    },
    {
      id: 4,
      title: 'SSLCOMMERZ',
      description:
        'Start accepting payments from bKash, Nagad, Rocket, Debit and Credit Cards and many other payment methods.',
      image: SslLogo,
      link: '/plugins/ssl',
      pluginAccessName: 'ssl_commerz',
    },
    {
      id: 6,
      title: 'STRIPE',
      description:
        'Stripe is a suite of APIs powering online payment processing and commerce solutions for internet businesses of all sizes.',
      image: StripeLogo,
      link: '/plugins/stripe',
      pluginAccessName: 'stripe',
    },
    {
      id: 7,
      title: 'WHATSAPP',
      description:
        'Use WhatsApp chat plugin on your website to help customers reach you for supports.',
      image: WhatsappLogo,
      link: '/plugins/whatsapp',
      pluginAccessName: 'whatsapp',
    },
  ]

  return (
    <Grid
      container
      sx={{
        overflowY: 'auto',
        height: '83vh',
        //hide scrollbar
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {data.map((plugin) => (
        <Plugin key={plugin.id} plugin={plugin} />
      ))}
    </Grid>
  )
}

export default Plugins
