import { Box, styled, TextField, Typography } from '@mui/material'
import jwtDecode from 'jwt-decode'
import React, { useState } from 'react'
import { useEffect } from 'react'
import OrangeButton from '../../components/Buttons/OrangeButton/OrangeButton'
import axios from '../InstanceAxios/InstanceAxios'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import './SoloThemeProductDetails.css'
import { toast, ToastContainer } from 'react-toastify'
import { Divider } from '@mui/material'
import TestimonialSection from './TestimonialSection'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--primary-text-color)',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'var(--primary-text-color)',
    },
  },
})

const SoloThemeProductDetails = () => {
  const [values, setValues] = useState({
    id: '',
    banner_img: '',
    feature_title: '',
    pre_tagline: '',
    tagline: '',
    tagline_description: '',
    product_one_liner: '',
    featured_img: '',
    feature_one_title: '',
    feature_one_description: '',
    feature_one_icon: '',
    feature_two_title: '',
    feature_two_description: '',
    feature_two_icon: '',
    feature_three_title: '',
    feature_three_description: '',
    feature_three_icon: '',
    feature_four_title: '',
    feature_four_description: '',
    feature_four_icon: '',
    embedded_content_link: '',
    delivery_charge: 0,
  })
  const [isDisabled, setIsDisabled] = useState(true)
  const [solo_theme_product_id, setSoloThemeProductId] = useState(null)

  const handleChange = (e) => {
    if (e.target.name.includes('img') || e.target.name.includes('icon')) {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
      })
    } else {
      const { name, value } = e.target
      setValues({
        ...values,
        [name]: value,
      })
    }
  }

  const handleUpdate = async () => {
    try {
      const url = `/soloTheme/updateSoloTheme/${values.id}`
      const formData = new FormData()
      for (let key in values) {
        formData.append(key, values[key])
      }
      const res = await axios.put(url, formData)
      if (res.data.status === 'S138') {
        toast.success('Theme information updated successfully', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
      } else {
        toast.error('Failed to update theme information', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    } catch (error) {
      console.log(error)
      toast.error('Failed to update theme information', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
    }
  }

  const getSoloThemeProductDetails = async () => {
    try {
      const { shop_id } = jwtDecode(localStorage.getItem('token'))
      const url = `/soloTheme/getSoloTheme/${shop_id}`
      const res = await axios.get(url)
      const fetchedData = {}
      for (let key in res.data) {
        fetchedData[key] = res.data[key]
      }
      setValues({ ...fetchedData })
      setSoloThemeProductId(res.data.id)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (
      (values.feature_title !== '' ||
        values.pre_tagline !== '' ||
        values.tagline !== '' ||
        values.tagline_description !== '' ||
        values.product_one_liner !== '' ||
        values.featured_img !== '' ||
        values.feature_one_title !== '' ||
        values.feature_one_descriptionL !== '' ||
        values.feature_one_icon !== '' ||
        values.feature_two_title !== '' ||
        values.feature_two_description !== '' ||
        values.feature_two_icon !== '' ||
        values.feature_three_title !== '' ||
        values.feature_three_description !== '' ||
        values.feature_three_icon !== '' ||
        values.feature_four_title !== '' ||
        values.feature_four_description !== '' ||
        values.feature_four_icon !== '' ||
        values.embedded_content_link !== '') &&
      values.delivery_charge.length !== 0
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [values])

  useEffect(() => {
    getSoloThemeProductDetails()
  }, [])

  return (
    <Box
      sx={{
        padding: '24px',
      }}>
      <ToastContainer autoClose={5000} closeOnClick />
      <Typography
        sx={{
          color: 'var(--primary-text-color)',
          fontSize: '22px',
          textAlign: 'left',
          marginBottom: '24px',
        }}>
        Solo Theme Product Details
      </Typography>
      <Box
        sx={{
          width: '70%',
        }}>
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_title'
          value={values.feature_title}
          id='outlined-basic'
          label='Feature Title'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='pre_tagline'
          value={values.pre_tagline}
          id='outlined-basic'
          label='Pre Tagline'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='tagline'
          value={values.tagline}
          id='outlined-basic'
          label='Tagline'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='tagline_description'
          value={values.tagline_description}
          id='outlined-basic'
          label='Tagline Description'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='product_one_liner'
          value={values.product_one_liner}
          id='outlined-basic'
          label='Product One Liner'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_one_title'
          value={values.feature_one_title}
          id='outlined-basic'
          label='Feature One Title'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_one_description'
          value={values.feature_one_description}
          id='outlined-basic'
          label='Feature One Description'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_two_title'
          value={values.feature_two_title}
          id='outlined-basic'
          label='Feature Two Title'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_two_description'
          value={values.feature_two_description}
          id='outlined-basic'
          label='Feature Two Description'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_three_title'
          value={values.feature_three_title}
          id='outlined-basic'
          label='Feature Three Title'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_three_description'
          value={values.feature_three_description}
          id='outlined-basic'
          label='Feature Three Description'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_four_title'
          value={values.feature_four_title}
          id='outlined-basic'
          label='Feature Four Title'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='feature_four_description'
          value={values.feature_four_description}
          id='outlined-basic'
          label='Feature Four Description'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='embedded_content_link'
          value={values.embedded_content_link}
          id='outlined-basic'
          label='Embedded Content Link'
          variant='outlined'
          fullWidth
        />
        <CssTextField
          sx={{ margin: '6px 0' }}
          onChange={(e) => handleChange(e)}
          name='delivery_charge'
          value={values.delivery_charge}
          id='outlined-basic'
          label='Delivery Charge'
          variant='outlined'
          fullWidth
        />
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '24px 0' }}>
        {values.banner_img ? (
          <Box sx={{ marginRight: '24px' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Banner Image
            </Typography>
            <div
              style={{
                border: '1px solid #E5E5E5',
              }}>
              {typeof values.banner_img == 'string' ? (
                <img
                  src={values.banner_img}
                  width='360px'
                  height='160px'
                  alt='upload'
                />
              ) : (
                <img
                  src={URL.createObjectURL(values.banner_img)}
                  height='160px'
                  width='360px'
                  alt='upload'
                />
              )}
            </div>
            <Box sx={{ marginTop: '-160px', marginRight: '-330px' }}>
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, banner_img: '' })
                }}
                sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        ) : (
          <div
            style={{
              marginRight: '24px',
            }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Banner Image
            </Typography>
            <label className='solo-theme-addImage-div-two'>
              <UploadFileOutlinedIcon
                sx={{ width: '20px', height: '23px', marginBottom: '5px' }}
              />
              <p>
                Tap to upload a featured cover here <br />
                (766 x 576) pixel
              </p>
              <br />
              <input
                type='file'
                name='banner_img'
                onChange={(e) => handleChange(e)}
                accept='image/png , image/jpeg, image/webp'
              />
            </label>
          </div>
        )}
        {values.featured_img ? (
          <Box>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Featured Image
            </Typography>
            <div
              style={{
                border: '1px solid #E5E5E5',
              }}>
              {typeof values.featured_img == 'string' ? (
                <img
                  src={values.featured_img}
                  width='160px'
                  height='160px'
                  alt='upload'
                />
              ) : (
                <img
                  src={URL.createObjectURL(values.featured_img)}
                  height='160px'
                  width='160px'
                  alt='upload'
                />
              )}
            </div>
            <Box sx={{ marginTop: '-160px', marginRight: '-130px' }}>
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, featured_img: '' })
                }}
                sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        ) : (
          <div>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Featured Image
            </Typography>
            <label className='solo-theme-addImage-div'>
              <UploadFileOutlinedIcon
                sx={{ width: '20px', height: '23px', marginBottom: '5px' }}
              />
              <p>
                Tap to upload a featured cover here <br />
                (766 x 576) pixel
              </p>
              <br />
              <input
                type='file'
                name='featured_img'
                onChange={(e) => handleChange(e)}
                accept='image/png , image/jpeg, image/webp'
              />
            </label>
          </div>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: `${
            values.banner_img && values.featured_img ? '130px' : ''
          }`,
        }}>
        {values.feature_one_icon ? (
          <Box sx={{ padding: '12px' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Feature One Icon
            </Typography>
            <div
              style={{
                border: '1px solid #E5E5E5',
              }}>
              {typeof values.feature_one_icon == 'string' ? (
                <img
                  src={values.feature_one_icon}
                  width='64px'
                  height='70px'
                  alt='upload'
                />
              ) : (
                <img
                  src={URL.createObjectURL(values.feature_one_icon)}
                  height='70px'
                  width='64px'
                  alt='upload'
                />
              )}
            </div>
            <Box sx={{ marginTop: '-64px', marginRight: '-80px' }}>
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, feature_one_icon: '' })
                }}
                sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        ) : (
          <div
            style={{
              padding: '12px',
            }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Feature One Icon
            </Typography>
            <label className='solo-theme-add-icon'>
              <UploadFileOutlinedIcon
                sx={{ width: '20px', height: '23px', marginBottom: '5px' }}
              />
              <p>Tap to upload a photo</p>
              <br />
              <input
                type='file'
                name='feature_one_icon'
                onChange={(e) => handleChange(e)}
                accept='image/png , image/jpeg, image/webp'
              />
            </label>
          </div>
        )}
        {values.feature_two_icon ? (
          <Box sx={{ padding: '12px' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Feature Two Icon
            </Typography>
            <div
              style={{
                border: '1px solid #E5E5E5',
              }}>
              {typeof values.feature_two_icon == 'string' ? (
                <img
                  src={values.feature_two_icon}
                  width='64px'
                  height='70px'
                  alt='upload'
                />
              ) : (
                <img
                  src={URL.createObjectURL(values.feature_two_icon)}
                  height='70px'
                  width='64px'
                  alt='upload'
                />
              )}
            </div>
            <Box sx={{ marginTop: '-64px', marginRight: '-80px' }}>
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, feature_two_icon: '' })
                }}
                sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        ) : (
          <div
            style={{
              padding: '12px',
            }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Feature Two Icon
            </Typography>
            <label className='solo-theme-add-icon'>
              <UploadFileOutlinedIcon
                sx={{ width: '20px', height: '23px', marginBottom: '5px' }}
              />
              <p>Tap to upload a photo</p>
              <br />
              <input
                type='file'
                name='feature_two_icon'
                onChange={(e) => handleChange(e)}
                accept='image/png , image/jpeg, image/webp'
              />
            </label>
          </div>
        )}
        {values.feature_three_icon ? (
          <Box sx={{ padding: '12px' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Feature Three Icon
            </Typography>
            <div
              style={{
                border: '1px solid #E5E5E5',
              }}>
              {typeof values.feature_three_icon == 'string' ? (
                <img
                  src={values.feature_three_icon}
                  width='64px'
                  height='70px'
                  alt='upload'
                />
              ) : (
                <img
                  src={URL.createObjectURL(values.feature_three_icon)}
                  height='70px'
                  width='64px'
                  alt='upload'
                />
              )}
            </div>
            <Box sx={{ marginTop: '-64px', marginRight: '-80px' }}>
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, feature_three_icon: '' })
                }}
                sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        ) : (
          <div
            style={{
              padding: '12px',
            }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Feature Three Icon
            </Typography>
            <label className='solo-theme-add-icon'>
              <UploadFileOutlinedIcon
                sx={{ width: '20px', height: '23px', marginBottom: '5px' }}
              />
              <p>Tap to upload a photo</p>
              <br />
              <input
                type='file'
                name='feature_three_icon'
                onChange={(e) => handleChange(e)}
                accept='image/png , image/jpeg, image/webp'
              />
            </label>
          </div>
        )}
        {values.feature_four_icon ? (
          <Box sx={{ padding: '12px' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Feature Four Icon
            </Typography>
            <div
              style={{
                border: '1px solid #E5E5E5',
              }}>
              {typeof values.feature_four_icon == 'string' ? (
                <img
                  src={values.feature_four_icon}
                  width='64px'
                  height='70px'
                  alt='upload'
                />
              ) : (
                <img
                  src={URL.createObjectURL(values.feature_four_icon)}
                  height='70px'
                  width='64px'
                  alt='upload'
                />
              )}
            </div>
            <Box sx={{ marginTop: '-64px', marginRight: '-80px' }}>
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, feature_four_icon: '' })
                }}
                sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        ) : (
          <div
            style={{
              padding: '12px',
            }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#000000',
                marginBottom: '8px',
              }}>
              Feature Four Icon
            </Typography>
            <label className='solo-theme-add-icon'>
              <UploadFileOutlinedIcon
                sx={{ width: '20px', height: '23px', marginBottom: '5px' }}
              />
              <p>Tap to upload a photo</p>
              <br />
              <input
                type='file'
                name='feature_four_icon'
                onChange={(e) => handleChange(e)}
                accept='image/png , image/jpeg, image/webp'
              />
            </label>
          </div>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}>
        <OrangeButton
          title='Update'
          handleOnClick={handleUpdate}
          isDisabled={isDisabled}
        />
      </Box>
      <Divider sx={{ marginTop: '24px' }} />
      {solo_theme_product_id && (
        <TestimonialSection solo_theme_product_id={solo_theme_product_id} />
      )}
    </Box>
  )
}

export default SoloThemeProductDetails
