import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: 'Exo',
  },
  components: {
    MuiTypography: {
      color: '#2d2d2d',
    },
  },
})
