import React, { useState } from 'react'
import DomainSettingsHome from './DomainSettingsHome'
import './DomainSettings.css'
import DomainSettingsAdd from './DomainSettingsAdd'

const DomainSettings = ({ goToSubscription }) => {
  const [currentComponent, setCurrentComponent] = useState(1)

  const changeComponent = (direction) => {
    if (direction == 'next') {
      setCurrentComponent((cc) => Number(cc) + 1)
    } else {
      setCurrentComponent((cc) => Number(cc) - 1)
    }
  }
  const components = {
    1: (
      <DomainSettingsHome
        changeComponent={changeComponent}
        goToSubscription={goToSubscription}
      />
    ),
    2: <DomainSettingsAdd changeComponent={changeComponent} />,
  }
  return <>{components[currentComponent]}</>
}

export default DomainSettings
