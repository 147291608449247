import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useState } from 'react'
import EbebshaLogo from '../../Images/EbebshaDark.png'
import OnboardSlider from '../Shared/OnboardSlider/OnboardSlider'
import axios from '../InstanceAxios/InstanceAxios'
import { useNavigate } from 'react-router-dom'
import './SignUp.css'
import { useEffect } from 'react'
import LoginHooks from '../../components/LoginHooks/LoginHooks'
import mixpanel from 'mixpanel-browser'
import { SIGN_UP, VIEW_SIGNUP_PAGE } from '../../utilities/MixpanelConst'
import jwt_decode from 'jwt-decode'
import { useContext } from 'react'
import { AuthContext } from '../../Contexts/AuthContext'
import { NotificationContext } from '../../Contexts/NotificationContext'
import { ErrorContext } from '../../Contexts/ErrorContext'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: 'var(--primary-text-color)',
//   },
//   '& .MuiOutlinedInput-root': {
//     '&.Mui-focused fieldset': {
//       borderColor: 'var(--primary-text-color)',
//     },
//   },
// })

const SignUp = () => {
  const [name, setName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [pin, setPin] = useState('')
  const [checkBox, setCheckBox] = useState('')
  const [pinError, setPinError] = useState(null)
  const [numberError, setNumberError] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const { setToken } = useContext(AuthContext)
  const { showNotification } = useContext(NotificationContext)
  const { error, setError } = useContext(ErrorContext)
  const navigate = useNavigate()

  useEffect(() => {
    mixpanel.track(VIEW_SIGNUP_PAGE)
  }, [])

  const handlePhone = (e) => {
    if (e.target.value.length > 11) {
      return
    }
    setMobileNumber(e.target.value)
    if (e.target.value.length === 11) {
      const pattern = /((01){1}[3456789]{1}(\d){8})$/
      if (pattern.test(e.target.value)) {
        setNumberError(null)
      } else {
        setNumberError('Please Enter a valid mobile number')
      }
    } else {
      setNumberError('Please Enter 11 digits number')
    }
  }

  const validatePincode = (value) => {
    setPin(value)
    const pattern = /^\d+$/
    if (pattern.test(value)) {
      if (value.length !== 5) {
        setPinError('Pincode must be 5 digits long')
      } else {
        setPinError(null)
      }
    } else {
      setPinError('Only Numbers are Allowed')
    }
  }

  const signUp = async (data) => {
    try {
      const res = await axios.get(`/merchants/existingMerchant/${mobileNumber}`)
      if (!res.data) {
        const response = await axios.post('/merchants/signup', {
          merchant_id: Number(data.mobileNumber),
          shop_name: '',
          user_name: data.name,
          password: data.pin,
          phone: data.mobileNumber,
          email: '',
          subscription_package_id: 1,
          referral: '',
        })
        localStorage.setItem('token', response.data.accessToken)
        setToken(response.data.accessToken)
        if (response?.data?.status === 'S101') {
          const res = await axios.post('/merchants/sendOtp', {
            phone: data.mobileNumber,
          })

          localStorage.setItem('mobileVerificationPhone', data.mobileNumber)

          //mixpanel
          mixpanel.alias(jwt_decode(response.data.accessToken).merchant_id)
          mixpanel.identify(jwt_decode(response.data.accessToken).merchant_id)
          mixpanel.register({
            $PRICING_PLAN: 'Trial',
          })
          mixpanel.track(SIGN_UP, {})
          mixpanel.people.set({
            $NAME: data.name,
            $PHONE: data.mobileNumber,
            $REFERRAL_CODE: '',
            $TOTAL_PRODUCTS: 0.0,
            $TOTAL_COLLECTIONS: 0.0,
            $TOTAL_CAMPAIGNS: 0.0,
            $TOTAL_ORDERS: 0.0,
            $TOTAL_DELIVERED_ORDERS: 0.0,
            $STORE_LOGO: 'No',
            $STORE_ADDRESS: 'No',
            $STORE_BANNER: 'No',
          })
          navigate('/mobileVerification')
        }
      } else {
        setError({
          loginPageError: 'An account exists with this number. Please Log In',
        })
        navigate('/login')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsDisabled(true)
    signUp({ name, mobileNumber, pin })
  }
  useEffect(() => {
    if (checkBox) {
      if (name.length && !numberError && !pinError) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    } else {
      setIsDisabled(true)
    }
  }, [name, mobileNumber, pin, checkBox])

  return (
    <Grid container>
      <Grid
        item
        lg={5}
        sx={{
          overflowY: 'auto',
          height: '100vh',
          //hide scrollbar
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            margin: { md: '0 112px', xs: '0 35px' },
            position: 'relative',
            height: '100%',
          }}
        >
          <Box sx={{ top: '50px', position: 'absolute' }}>
            <img src={EbebshaLogo} alt='E-bebsha Logo' width={100} />
          </Box>
          {/* <Box sx={{ margin: "70px 0" }}>
                        <Typography variant="body1" sx={{ fontSize: "36px", fontFamily: "Exo" }}>
                            Sign up to get 7 days free trial
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "18px", fontFamily: "Exo", color: "#95989A", marginTop: "16px" }}>
                            Create beautiful store for your eCommerce business
                        </Typography>
                    </Box> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}
          >
            {/* <FormControl sx={{ width: '100%' }}>
                            <CssTextField
                                label="Your Name"
                                placeholder="Enter your Name"
                                variant="outlined"
                                sx={{ margin: "8px 0" }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl sx={{ width: '100%' }}>
                            <CssTextField
                                label="Your Phone Number"
                                placeholder="01XXXXXXXXX"
                                variant="outlined"
                                sx={{ margin: "8px 0" }}
                                value={mobileNumber}
                                onChange={(e) => handlePhone(e)}
                                aria-describedby="number-text"
                                error={numberError != null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+88</InputAdornment>,
                                }}
                            />
                            {
                                numberError &&
                                <FormHelperText
                                    error={numberError != null}
                                    sx={{ fontSize: "8px", paddingLeft: "12px", color: "var(--primary-text-color)" }}
                                    id="number-text"
                                >
                                    {numberError}
                                </FormHelperText>
                            }
                        </FormControl>
                        <FormControl sx={{ width: '100%' }}>
                            <CssTextField
                                label="Pincode"
                                placeholder="Set a new pincode"
                                variant="outlined"
                                sx={{ margin: "8px 0" }}
                                type="password"
                                aria-describedby="pincode-text"
                                value={pin}
                                onChange={(e) => validatePincode(e.target.value)}
                                error={pinError != null}
                            />
                        </FormControl>
                        {
                            pinError &&
                            <FormHelperText
                                error={pinError != null}
                                sx={{ fontSize: "8px", paddingLeft: "12px", color: "var(--primary-text-color)" }}
                                id="pincode-text"
                            >
                                {pinError}
                            </FormHelperText>
                        }
                        <FormControlLabel
                            sx={{ padding: "0", margin: "0" }}
                            control={<Radio
                                value="checked"
                                onChange={(e) => setCheckBox(e.target.value)} />}
                        />
                        <Typography variant="body1" sx={{ fontSize: "14px", fontFamily: "Exo", margin: "0", textAlign: "center", display: "inline", padding: "0" }}>
                            By creating an account you agree to the
                            <span
                                style={{ color: "var(--primary-bg-color)" }}
                                onClick={() => navigate("/")}> terms of use
                            </span> and our
                            <span
                                style={{ color: "var(--primary-bg-color)" }}
                                onClick={() => navigate("/")}> private policy?
                            </span>
                        </Typography>
                        <Button
                            variant="contained"
                            disableElevation
                            disabled={isDisabled}
                            onClick={handleSubmit}
                            sx={{
                                width: '100%',
                                backgroundColor: "var(--primary-bg-color)",
                                marginTop: "40px",
                                '&:hover': { backgroundColor: 'var(--primary-bg-color)' },
                                height: "48px",
                                fontSize: "16px",
                            }}>
                            Sign Up
                        </Button> */}
            <LoginHooks />
            <Typography
              variant='body1'
              sx={{
                fontSize: '14px',
                fontFamily: 'Exo',
                margin: '24px 0 50px 0',
                textAlign: 'center',
              }}
            >
              Already have an account?{' '}
              <span
                style={{ color: 'var(--primary-bg-color)' }}
                onClick={() => navigate('/login')}
              >
                Sign In
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={7} sx={{ display: { xs: 'none', lg: 'block' } }}>
        <OnboardSlider />
      </Grid>
    </Grid>
  )
}

export default SignUp
