import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FailGif from '../../Images/cancel.gif'

const PaymentFailed = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const ColorButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    border: '1px solid var(--primary-text-color)',
    backgroundColor: 'var(--primary-text-color)',
    fontSize: '14px',
    fontWeight: 'normal',
    height: '45px',
    color: '#FFFFFF',
    marginTop: '40px',
    padding: '20px',
    fontFamily: 'Exo',
    '&:active': {
      backgroundColor: '#FFFFFF',
    },
    '&:hover': {
      border: '1px solid var(--primary-text-color)',
      backgroundColor: '#FFFFFF',
      color: 'var(--primary-text-color)',
    },
  }))

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/settings/subscription')
    }, [10000])

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '70vh',
        }}>
        <img style={{ width: '150px', height: '150px' }} src={FailGif} alt='' />
        <Typography
          sx={{
            color: 'var(--primary-text-color)',
            fontSize: '24px',
            fontFamily: 'Quicksand',
          }}>
          Your payment is{' '}
          {location.pathname.includes('cancelled') ? 'Cancelled' : 'Failed'}
        </Typography>
        <Typography
          sx={{
            color: '#95989A',
            fontSize: '16px',
            fontFamily: 'Quicksand',
          }}>
          You will be shortly redirect to Subscription page
        </Typography>
        <ColorButton
          disableElevation
          size='small'
          onClick={() => navigate('/settings/subscription')}>
          Continue To Subscription Page
        </ColorButton>
      </Box>
    </>
  )
}

export default PaymentFailed
