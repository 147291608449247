import React, { useState } from 'react'
import './DeliverySettings.css'
import DeliverySettingsHome from './DeliverySettingsHome'
import DeliverySettingsPickUpAddress from './DeliverySettingsPickUp'

const DeliverySettings = ({ goToSubscription }) => {
  const [currentComponent, setCurrentComponent] = useState(1)

  const changeComponent = (direction) => {
    if (direction == 'next') {
      setCurrentComponent((cc) => Number(cc) + 1)
    } else {
      setCurrentComponent((cc) => Number(cc) - 1)
    }
  }
  const components = {
    1: (
      <DeliverySettingsHome
        changeComponent={changeComponent}
        goToSubscription={goToSubscription}
      />
    ),
    2: <DeliverySettingsPickUpAddress changeComponent={changeComponent} />,
  }
  return <>{components[currentComponent]}</>
}

export default DeliverySettings
