import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  OutlinedInput,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useState } from 'react'
import BonikLogo from '../../Images/bonik-logo.png'
import OnboardSlider from '../Shared/OnboardSlider/OnboardSlider'
import axios from '../InstanceAxios/InstanceAxios'
import { useNavigate } from 'react-router-dom'
import './ForgotPincode.css'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--primary-text-color)',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'var(--primary-text-color)',
    },
  },
})

const ForgotPincode = () => {
  const [mobileNumber, setMobileNumber] = useState('')
  const [numberError, setNumberError] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const navigate = useNavigate()

  const isMerchantExist = async () => {
    try {
      const response = await axios.get(
        `/merchants/existingMerchant/${mobileNumber}`
      )
      if (response.data) {
        try {
          const response = await axios.post('/merchants/sendOtp', {
            phone: mobileNumber,
          })
          localStorage.setItem('forgetPinPhone', mobileNumber)
          navigate('/forgotPincodeOtp')
        } catch (error) {
          console.log(error)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e) => {
    if (e.target.value.length > 11) {
      return
    }
    setMobileNumber(e.target.value)
    if (e.target.value.length === 11) {
      const pattern = /((01){1}[3456789]{1}(\d){8})$/
      if (pattern.test(e.target.value)) {
        setNumberError(null)
        setIsDisabled(false)
      } else {
        setNumberError('Please Enter a valid mobile number')
        setIsDisabled(true)
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsDisabled(true)
    isMerchantExist()
  }

  return (
    <Grid container>
      <Grid item lg={5}>
        <Box sx={{ margin: { md: '0 112px', xs: '0 35px' } }}>
          <Box sx={{ marginTop: '50px' }}>
            <img src={BonikLogo} alt='E-bebsha Logo' height={'40px'} />
          </Box>
          <Box sx={{ margin: '70px 0' }}>
            <Typography
              variant='body1'
              sx={{ fontSize: '36px', fontFamily: 'Exo' }}
            >
              Forgot Pincode
            </Typography>
            <Typography
              variant='body1'
              sx={{
                fontSize: '14px',
                fontFamily: 'Exo',
                color: '#95989A',
                marginTop: '16px',
              }}
            >
              Please enter your phone number and we will send you an OTP to set
              your pincode
            </Typography>
          </Box>
          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            <Box display='flex' alignItems='center' flexDirection='column'>
              <CssTextField
                id='standard-basic'
                variant='standard'
                value={mobileNumber}
                onChange={(e) => handleChange(e)}
                error={numberError != null}
                aria-describedby='mobile'
                sx={{ marginTop: '80px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>+88</InputAdornment>
                  ),
                }}
              />
              {numberError ? (
                <FormHelperText id='mobile' error={numberError != null}>
                  {numberError}
                </FormHelperText>
              ) : (
                <Typography
                  variant='body1'
                  sx={{
                    fontSize: '14px',
                    fontFamily: 'Exo',
                    color: '#95989A',
                    marginTop: '16px',
                  }}
                >
                  Please enter your phone number
                </Typography>
              )}
            </Box>
            <Button
              type='submit'
              variant='contained'
              disableElevation
              sx={{
                width: '100%',
                backgroundColor: 'var(--primary-bg-color)',
                marginTop: '120px',
                '&:hover': { backgroundColor: 'var(--primary-bg-color)' },
              }}
              disabled={isDisabled}
            >
              Get OTP
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={7} sx={{ display: { xs: 'none', lg: 'block' } }}>
        <OnboardSlider />
      </Grid>
    </Grid>
  )
}

export default ForgotPincode
