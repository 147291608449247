import { Button } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../../Pages/InstanceAxios/InstanceAxios'
import jwtDecode from 'jwt-decode'
import PaperflyLogo from '../../Images/paperfly-logo.png'
import PaperflyFeatures from './PaperflyFeatures'
import PaperflyInstrauctions from './PaperflyInstrauctions'

const PluginPaperfly = () => {
  const { shop_id } = jwtDecode(localStorage.getItem('token'))
  const [isShowDetails, setIsShowDetails] = useState(true)
  const [isOpenedDialouge, setIsOpenedDialouge] = useState(false)
  const [data, setData] = useState({})
  const navigate = useNavigate()

  const selected = {
    padding: '8px 16px',
    backgroundColor: 'var(--primary-text-color)',
    border: '1px solid var(--primary-text-color)',
    borderRadius: '6px',
    textTransform: 'none',
    fontFamily: 'Exo',
    '&:hover': {
      border: '1px solid var(--primary-text-color)',
      borderColor: 'var(--primary-text-color)',
      backgroundColor: 'var(--primary-text-color)',
    },
  }
  const notSelected = {
    padding: '8px 16px',
    backgroundColor: 'white',
    border: '1px solid var(--primary-text-color)',
    borderRadius: '6px',
    color: 'var(--primary-text-color)',
    textTransform: 'none',
    fontFamily: 'Exo',
    '&:hover': {
      border: '1px solid var(--primary-text-color)',
      backgroundColor: 'white',
      color: 'var(--primary-text-color)',
    },
  }

  const handleInstall = () => {
    setIsOpenedDialouge(true)
  }

  const handleUninstall = async () => {
    try {
      const { shop_id } = jwtDecode(localStorage.getItem('token'))
      const url = `/delivery/updateDeliveryPartner/${shop_id}`
      const res = await axios.put(url, {
        delivery_partner_id: null,
      })
      getPaperflyData()
    } catch (error) {
      console.log(error)
    }
  }

  const getData = async () => {
    try {
      getPaperflyData()
    } catch (error) {
      console.log(error)
    }
  }

  const getPaperflyData = async () => {
    try {
      const { shop_id } = jwtDecode(localStorage.getItem('token'))
      const url = `/delivery/getDeliveryPartner/${shop_id}`
      const res = await axios.get(url)
      if (res.data.id === 'd1') {
        setData(res.data)
      } else {
        setData({})
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!isOpenedDialouge) {
      getData()
    }
  }, [isOpenedDialouge])

  return (
    <div
      className='single-plugin-container scroll'
      style={{ overflowY: 'auto', height: '70vh' }}
    >
      <div className='single-plugin-header'>
        <div>
          <img src={PaperflyLogo} height='120px' width='120px' alt='' />
        </div>
        <div className='single-plugin-header-right'>
          <p className='single-plugin-title'>Paperfly</p>
          {Object.keys(data).length == 0 || Object.values(data)[0] === null ? (
            <Button
              disableElevation
              variant='contained'
              sx={{
                padding: '8px 16px',
                backgroundColor: 'var(--primary-bg-color)',
                borderRadius: '6px',
                fontFamily: 'Exo',
                textTransform: 'none',
                '&:hover': {
                  // borderColor: '# ED5E4A',
                  backgroundColor: '# ED5E4A',
                },
              }}
              onClick={handleInstall}
            >
              Install
            </Button>
          ) : (
            <span>
              <Button
                disableElevation
                variant='contained'
                sx={{
                  padding: '8px 16px',
                  marginRight: '16px',
                  backgroundColor: 'var(--primary-bg-color)',
                  borderRadius: '6px',
                  fontFamily: 'Exo',
                  textTransform: 'none',
                  '&:hover': {
                    // borderColor: '# ED5E4A',
                    filter: 'brightness(1.15)',
                    backgroundColor: 'var(--primary-bg-color)',
                  },
                }}
                onClick={handleInstall}
              >
                Setting
              </Button>
              <Button
                disableElevation
                variant='outlined'
                sx={{
                  padding: '8px 16px',
                  color: 'var(--primary-bg-color)',
                  border: '1px solid var(--primary-bg-color)',
                  borderRadius: '6px',
                  fontFamily: 'Exo',
                  textTransform: 'none',
                  '&:hover': {
                    border: '1px solid var(--primary-bg-color)',
                    color: 'var(--primary-bg-color)',
                    backgroundColor: 'var(--primary-bg-color)',
                  },
                }}
                onClick={handleUninstall}
              >
                Uninstall
              </Button>
            </span>
          )}
        </div>
      </div>
      <div className='single-plugin-btn-div'>
        <Button
          disableElevation
          variant='contained'
          sx={isShowDetails ? selected : notSelected}
          onClick={() => setIsShowDetails(true)}
        >
          Details
        </Button>
        <Button
          disableElevation
          variant='contained'
          sx={
            isShowDetails
              ? { marginLeft: '24px', ...notSelected }
              : { marginLeft: '24px', ...selected }
          }
          onClick={() => setIsShowDetails(false)}
        >
          Instruction
        </Button>
      </div>
      <div className='single-plugin-description'>
        {isShowDetails ? <PaperflyFeatures /> : <PaperflyInstrauctions />}
      </div>
    </div>
  )
}

export default PluginPaperfly
