import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Typography,
} from '@mui/material'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import { Box } from '@mui/system'
import React, { memo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Selected from '../../../../Images/selected.svg'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

const EditVariantModal = ({
  selectedImages,
  index,
  handleVariantImage,
  variantProd,
  handleVariantImageId,
}) => {
  const images = selectedImages
  let selectedImg = []

  images.map((r) => {
    selectedImg.push(false)
  })

  let imageSrc

  images.map((r) => {
    if (r.image_id === variantProd.image_id) {
      imageSrc = r.image_url
      return imageSrc
    }
  })

  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(selectedImg)

  const handleClick = () => {
    setOpen(!open)
  }
  let variantIndex = index

  const handleClickRadio = (index) => {
    let temp = [...checked]
    temp = temp.map((r) => false)
    temp[index] = true
    setChecked(temp)
    handleVariantImage(index, variantIndex)
  }

  const handleClickRadioImgUrl = (id, index) => {
    let temp = [...checked]
    temp = temp.map((r) => false)
    temp[index] = true
    setChecked(temp)
    handleVariantImageId(id, variantIndex)
  }

  return (
    <>
      {variantProd.image_id ? (
        <img
          src={imageSrc}
          onClick={handleClick}
          style={{ width: '40px', height: '40px' }}
          alt='variant img'
        />
      ) : variantProd.image_index === -1 ||
        variantProd.image_index === null ||
        Object.hasOwnProperty.call(variantProd, 'image_index') === false ? (
        <UploadFileOutlinedIcon
          sx={{ width: '20px', height: '23px' }}
          onClick={handleClick}
        />
      ) : images[variantProd.image_index]?.image_url ? (
        <img
          src={images[variantProd?.image_index]?.image_url}
          onClick={handleClick}
          style={{ width: '40px', height: '40px' }}
          alt='variant img'
        />
      ) : (
        <Box
          onClick={handleClick}
          sx={{
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AddPhotoAlternateIcon />
        </Box>
      )}

      <Dialog fullWidth open={open} onClose={handleClick}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              color: 'var(--primary-text-color)',
            }}
          >
            Select a Picture
          </Typography>
          <Box
            sx={{
              width: '18px',
              height: '18px',
              cursor: 'pointer',
              color: 'var(--primary-bg-color)',
            }}
            onClick={handleClick}
          >
            <CloseIcon />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex' }}>
          {images &&
            images?.map((image, index) => {
              return (
                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  {image.image_url ? (
                    <Box
                      onClick={() =>
                        handleClickRadioImgUrl(image.image_id, index)
                      }
                    >
                      {checked[index] && (
                        <Box
                          component='img'
                          src={Selected}
                          sx={{
                            width: '18px',
                            height: '18px',
                            zIndex: '2',
                            objectFit: 'cover',
                            position: 'absolute',
                            top: '4',
                            padding: '5px',
                            marginLeft: '63px',
                          }}
                        />
                      )}
                      <Box
                        component='img'
                        src={image.image_url}
                        sx={{
                          marginTop: '5px',
                          height: '92px',
                          width: '92px',
                          borderRadius: '4px',
                          marginRight: '24px',
                          display: 'flex',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        alt='upload'
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          zIndex: '1',
                          top: '20',
                          bottom: '0',
                          height: '90px',
                          width: '90px',
                          color: 'red',
                          backgroundColor: 'rgba(102, 102, 102, 0.6)',
                          borderRadius: '4px',
                          opacity: checked[index] ? '1' : '0',
                          border: '2px solid var(--primary-text-color)',
                          '&:hover': {
                            opacity: '1',
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                      onClick={() => handleClickRadio(index)}
                    >
                      {checked[index] && (
                        <Box
                          component='img'
                          src={Selected}
                          sx={{
                            width: '18px',
                            height: '18px',
                            zIndex: '2',
                            objectFit: 'cover',
                            position: 'absolute',
                            top: '0',
                            padding: '5px',
                            marginLeft: '63px',
                          }}
                        />
                      )}
                      <Box
                        component='img'
                        src={image}
                        sx={{
                          marginTop: '5px',
                          height: '92px',
                          width: '92px',
                          borderRadius: '4px',
                          marginRight: '24px',
                          display: 'flex',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        alt='upload'
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          zIndex: '1',
                          top: '20',
                          bottom: '0',
                          height: '90px',
                          width: '90px',
                          color: 'red',
                          backgroundColor: 'rgba(102, 102, 102, 0.6)',
                          borderRadius: '4px',
                          opacity: checked[index] ? '1' : '0',
                          border: '2px solid var(--primary-text-color)',
                          '&:hover': {
                            opacity: '1',
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )
            })}
          <Box sx={{ marginTop: '5px' }}></Box>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default memo(EditVariantModal)
