import { Button, InputAdornment, styled, TextField } from '@mui/material'
import jwtDecode from 'jwt-decode'
import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from '..//InstanceAxios/InstanceAxios'
import BdCurrency from '../../Images/bd_currency.png'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--primary-text-color)',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'var(--primary-text-color)',
    },
  },
})

const DeliverySettingsCharge = (props) => {
  const [data, setData] = useState({
    inside_district: '',
    inside_division: '',
    outside_division: '',
  })
  const [isDisabled, setIsDisabled] = useState(true)
  const [isSave, setIsSave] = useState(true)

  const handleSubmit = async () => {
    const decoded = jwtDecode(localStorage.getItem('token'))
    const url = `/merchants/updateSelfDeliveryDetails/${decoded.shop_id}`
    setIsDisabled(true)
    try {
      const resposne = await axios.put(url, {
        ...data,
        free_delivery_purchase_amount: 0,
        shop_id: decoded.shop_id,
      })
      if (resposne.data.status === 'S128') {
        toast.success('Pickup charge updated', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    } catch (error) {
      toast.error('Pickup charge failed to update.', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
      console.log(error)
    }
  }

  const handleChange = (event) => {
    const oldData = { ...data }
    oldData[event.target.name] = Number(event.target.value)
    setData({ ...oldData })
  }

  const getSelfDeliveryFee = async () => {
    try {
      const decoded = jwtDecode(localStorage.getItem('token'))
      const url = `/merchants/getSelfDeliveryDetails/${decoded.shop_id}`
      const res = await axios.get(url)
      if (res.data != null) {
        setIsSave(false)
        setData({
          inside_district: Number(res.data.inside_district),
          inside_division: Number(res.data.inside_division),
          outside_division: Number(res.data.outside_division),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (data.inside_district && data.inside_division && data.outside_division) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [data.inside_district, data.inside_division, data.outside_division])

  useEffect(() => {
    getSelfDeliveryFee()
  }, [])

  return (
    <div className=''>
      <div className='pickup-adrs-form'>
        <p className='delivery-title'>Set Delivery Charges</p>
        <CssTextField
          type='text'
          sx={{ margin: '10px 0' }}
          onChange={(e) => {
            const value = +e.target.value
            if (Number.isNaN(value)) return
            setData({
              ...data,
              inside_district: value,
            })
          }}
          value={data.inside_district}
          name='inside_district'
          id='outlined-basic'
          label='Inside District*'
          placeholder='Enter the delivery charge'
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={BdCurrency} height='24px' />
              </InputAdornment>
            ),
            readOnly: props.isReadOnly,
          }}
          fullWidth
        />
        <CssTextField
          type='text'
          sx={{ margin: '10px 0' }}
          onChange={(e) => {
            const value = +e.target.value
            if (Number.isNaN(value)) return
            setData({
              ...data,
              inside_division: value,
            })
          }}
          value={data.inside_division}
          name='inside_division'
          id='outlined-basic'
          label='Inside Division*'
          placeholder='Enter the delivery charge'
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={BdCurrency} height='24px' />
              </InputAdornment>
            ),
            readOnly: props.isReadOnly,
          }}
          fullWidth
        />
        <CssTextField
          type='text'
          sx={{ margin: '10px 0' }}
          onChange={(e) => {
            const value = +e.target.value
            if (Number.isNaN(value)) return
            setData({
              ...data,
              outside_division: value,
            })
          }}
          value={data.outside_division}
          name='outside_division'
          id='outlined-basic'
          label='Outside Division*'
          placeholder='Enter the delivery charge'
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={BdCurrency} height='24px' alt='bd currency' />
              </InputAdornment>
            ),
            readOnly: props.isReadOnly,
          }}
          fullWidth
        />
        <div className='place-right'>
          <Button
            disableElevation
            variant='contained'
            sx={{
              textTransform: 'none',
              backgroundColor: 'var(--primary-bg-color)',
              '&:hover': {
                // borderColor: 'var(--primary-bg-color)',
                filter: 'brightness(1.15)',
              },
            }}
            onClick={handleSubmit}
            disabled={isDisabled || props.isReadOnly}
          >
            {/* {isSave ? "Save and Enable" : "Enable"} */}
            Save and Enable
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeliverySettingsCharge
