import React from 'react'

const AnalyticsFeatures = () => {
  return (
    <>
      <p>
        Google Analytics is a website traffic analysis application that provides
        real-time statistics and analysis of user interaction with the website.
        <br /> <br />
        Google Analytics enables website owners to analyze their visitors, with
        the objective of interpreting and optimizing the website’s performance.
        Google Analytics can track all forms of digital media and referring
        upstream web destinations, banner and contextual advertisements, e-mail
        and integrates with other Google products.
      </p>
      <p className='single-plugin-details-title'>
        Why use Google Analytics for your business?
      </p>
      <ul className='single-plugin-ul'>
        <li className='single-plugin-li'>
          <span className='single-plugin-bold'>Complete Information:</span>{' '}
           Understand your site and app users to better check the performance of
          your marketing, content, products, and more.
        </li>
        <li className='single-plugin-li'>
          <span className='single-plugin-bold'>Get Insights:</span>  Access
          Google's unique insights and machine learning capabilities to make the
          most of your data.
        </li>
        <li className='single-plugin-li'>
          <span className='single-plugin-bold'>
            Connect Insights to Results:
          </span>{' '}
           Analytics works with Google's advertising and publisher products, so
          you can use your insights to deliver business results.
        </li>
      </ul>
    </>
  )
}

export default AnalyticsFeatures
