import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import IncreaseLogo from '../../Images/increase.png'
import DecreaseLogo from '../../Images/decrease.png'

const DashboardCard = ({ title, value, percentage }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '16px',
        textAlign: 'left',
        fontFamily: 'Exo',
      }}>
      <Typography
        sx={{
          color: '#95989A',
          fontSize: '16px',
          lineHeight: '24px',
          fontFamily: 'Exo',
        }}>
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '36px',
          lineHeight: '52px',
          color: 'var(--primary-text-color)',
          fontWeight: '400',
          marginTop: '4px',
          fontFamily: 'Exo',
        }}>
        {value}
      </Typography>
      {/* 
                Percentage and Since Last Month data
            */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '16px',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <img
            width='16px'
            height='10px'
            src={percentage >= 0 ? IncreaseLogo : DecreaseLogo}
            alt=''
          />
          <Box
            component='span'
            sx={{
              color: percentage >= 0 ? '#00DD64' : '#ED1C24',
              fontSize: '11px',
              marginLeft: '6px',
              fontFamily: 'Exo',
            }}>
            {percentage}%
          </Box>
        </Box>
        <Box
          component='span'
          sx={{
            color: '#95989A',
            fontSize: '10px',
            fontFamily: 'Exo',
          }}>
          Since Last Month
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardCard
