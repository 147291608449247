import React, { createContext } from 'react'
import { toast } from 'react-toastify'

export const NotificationContext = createContext()

const NotificationProvider = ({ children }) => {
  const showNotification = (type, message) => {
    if (type === 'success') {
      toast.success(message, {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
    } else if (type === 'error') {
      toast.error(message, {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
    }
  }

  const NotificationInfo = {
    showNotification,
  }

  return (
    <NotificationContext.Provider value={NotificationInfo}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
