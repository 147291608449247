import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Cross from '../../Images/cross.png'
import axios from '../../Pages/InstanceAxios/InstanceAxios'

export default function VerifyPinModal({ open, setOpen, phone }) {
  const [otp, setOtp] = React.useState('')
  const [isDisabled, setIsDisabled] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (newValue) => {
    setOtp(newValue)
    if (newValue.length === 4) {
      const pattern = /^\d+$/
      if (pattern.test(newValue)) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    } else {
      setIsDisabled(true)
    }
  }

  const verifyOtp = async (data) => {
    try {
      const response = await axios.post('/merchants/verifyOtp', {
        phone,
        otp,
      })
      if (response.data.resetPasswordToken) {
        setOpen(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: 'var(--primary-text-color)',
              fontSize: '24px',
            }}
          >
            Mobile Verification
          </Typography>
          <img
            src={Cross}
            alt='Cross'
            onClick={handleClose}
            style={{
              cursor: 'pointer',
              height: '20px',
              width: '20px',
            }}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            marginTop: '0px',
          }}
        >
          <Typography
            sx={{
              color: '#95989A',
              fontSize: '14px',
              marginBottom: '20px',
            }}
          >
            Enter the OTP sent to{' '}
            <Box component='span' sx={{ color: 'var(--primary-text-color)' }}>
              +88{phone}
            </Box>
          </Typography>
          <MuiOtpInput value={otp} onChange={handleChange} />
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <Button
              variant='contained'
              disableElevation
              sx={{
                backgroundColor: 'var(--primary-bg-color)',
                borderRadius: '6px',
                textTransform: 'none',
                '&:hover': {
                  // borderColor: 'var(--primary-bg-color)',
                  filter: 'brightness(1.15)',
                },
              }}
              onClick={verifyOtp}
              disabled={isDisabled}
            >
              Verify & Proceed
            </Button>
          </Box>
          <Typography
            sx={{
              color: '#95989A',
              fontSize: '14px',
              marginTop: '12px',
              marginBottom: '24px',
            }}
          >
            Don’t receive the OTP?
            <Box component='span' sx={{ color: 'var(--primary-bg-color)' }}>
              {' '}
              Resend OTP
            </Box>
          </Typography>
        </DialogActions>
      </Dialog>
    </div>
  )
}
