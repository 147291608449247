import { Box, Typography, TextField, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import OrangeButton from '../../components/Buttons/OrangeButton/OrangeButton'
import CancelIcon from '@mui/icons-material/Cancel'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import axios from '../InstanceAxios/InstanceAxios'
import { toast, ToastContainer } from 'react-toastify'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--primary-text-color)',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'var(--primary-text-color)',
    },
  },
})

const TestimonialSection = ({ solo_theme_product_id }) => {
  const [values, setValues] = useState({
    customer_name: '',
    testimonial_text: '',
    rating: '',
    customer_photo: null,
  })
  const [open, setOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [testimonials, setTestimonials] = useState([])

  const handleOpen = (id) => {
    if (id) {
      const testimonial = testimonials[id - 1]
      setValues({
        id: testimonial.id,
        customer_name: testimonial.customer_name,
        testimonial_text: testimonial.testimonial_text,
        rating: testimonial.rating,
        customer_photo: testimonial.customer_photo,
      })
    }
    setOpen(true)
  }

  const handleClose = () => {
    setValues({
      id: '',
      customer_name: '',
      testimonial_text: '',
      rating: '',
      customer_photo: null,
    })
    setOpen(false)
  }

  const getAllTestimonials = async () => {
    try {
      const url = `/soloTheme/getAllTestimonials/${solo_theme_product_id}`
      const res = await axios.get(url)
      setTestimonials(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdate = async () => {
    try {
      handleClose()
      const formData = new FormData()
      formData.append('customer_name', values.customer_name)
      formData.append('testimonial_text', values.testimonial_text)
      formData.append('rating', values.rating)
      if (typeof values.customer_photo !== 'string') {
        formData.append('customer_photo', values.customer_photo)
      }
      const url = `/soloTheme/updateTestimonial/${values.id}`
      const res = await axios.post(url, formData)
      if (res.data.status === 'S141') {
        toast.success('Successfully saved testimonial', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        getAllTestimonials()
      } else {
        toast.error('Failed to save testimonial', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    } catch (error) {
      console.log(error)
      toast.error('Failed to save testimonial', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    } finally {
      setValues({
        id: '',
        customer_name: '',
        testimonial_text: '',
        rating: '',
        solo_theme_product_id: '',
        customer_photo: null,
      })
    }
  }

  const handleSave = async () => {
    try {
      handleClose()
      const formData = new FormData()
      formData.append('customer_name', values.customer_name)
      formData.append('testimonial_text', values.testimonial_text)
      formData.append('rating', values.rating)
      formData.append('solo_theme_product_id', solo_theme_product_id)
      formData.append('customer_photo', values.customer_photo)
      const url = `/soloTheme/createTestimonial`
      const res = await axios.post(url, formData)
      if (res.data.status === 'S140') {
        toast.success('Successfully saved testimonial', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        getAllTestimonials()
      } else {
        toast.error('Failed to save testimonial', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    } catch (error) {
      console.log(error)
      toast.error('Failed to save testimonial', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    } finally {
      setValues({
        customer_name: '',
        testimonial_text: '',
        rating: '',
        solo_theme_product_id: '',
        customer_photo: null,
      })
    }
  }

  const handleChange = (e) => {
    if (e.target.name.includes('photo')) {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
      })
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    }
  }

  useEffect(() => {
    if (
      values.customer_name &&
      values.testimonial_text &&
      values.rating &&
      values.customer_photo
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [values])

  useEffect(() => {
    getAllTestimonials()
  }, [solo_theme_product_id])

  return (
    <Box
      sx={{
        margin: '24px 0px',
      }}>
      <ToastContainer autoClose={5000} closeOnClick />
      <Typography
        sx={{
          color: 'var(--primary-text-color)',
          fontSize: '22px',
          textAlign: 'left',
          marginBottom: '24px',
        }}>
        Testimonial Section
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
        }}>
        {testimonials.length === 0 ? (
          <Box
            sx={{
              cursor: 'pointer',
              border: '1px solid #95989A',
              borderRadius: '8px',
              padding: '15px',
              color: '#95989A',
              width: '25%',
            }}
            onClick={() => handleOpen()}>
            Add Testimonial
          </Box>
        ) : (
          <Box
            sx={{
              cursor: 'pointer',
              border: '1px solid var(--primary-text-color)',
              borderRadius: '8px',
              padding: '15px',
              color: 'var(--primary-text-color)',
              width: '25%',
            }}
            onClick={() => handleOpen(1)}>
            View Testimonial
          </Box>
        )}
        {testimonials.length <= 1 ? (
          <Box
            sx={{
              cursor: 'pointer',
              border: '1px solid #95989A',
              borderRadius: '8px',
              padding: '15px',
              color: '#95989A',
              width: '25%',
            }}
            onClick={() => handleOpen()}>
            Add Testimonial
          </Box>
        ) : (
          <Box
            sx={{
              cursor: 'pointer',
              border: '1px solid var(--primary-text-color)',
              borderRadius: '8px',
              padding: '15px',
              color: 'var(--primary-text-color)',
              width: '25%',
            }}
            onClick={() => handleOpen(2)}>
            View Testimonial
          </Box>
        )}
        {testimonials.length <= 2 ? (
          <Box
            sx={{
              cursor: 'pointer',
              border: '1px solid #95989A',
              borderRadius: '8px',
              padding: '15px',
              color: '#95989A',
              width: '25%',
            }}
            onClick={() => handleOpen()}>
            Add Testimonial
          </Box>
        ) : (
          <Box
            sx={{
              cursor: 'pointer',
              border: '1px solid var(--primary-text-color)',
              borderRadius: '8px',
              padding: '15px',
              color: 'var(--primary-text-color)',
              width: '25%',
            }}
            onClick={() => handleOpen(3)}>
            View Testimonial
          </Box>
        )}
      </Box>
      <Dialog fullWidth open={open} onClose={() => handleClose()}>
        <DialogTitle>Add Testimonial</DialogTitle>
        <DialogContent
          className='scroll'
          sx={{ paddingX: '0px', height: '50%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '24px',
            }}>
            <CssTextField
              sx={{ margin: '6px 0' }}
              onChange={(e) => handleChange(e)}
              name='customer_name'
              value={values.customer_name}
              id='outlined-basic'
              label='Customer Name'
              variant='outlined'
              fullWidth
            />
            <CssTextField
              sx={{ margin: '6px 0' }}
              onChange={(e) => handleChange(e)}
              name='testimonial_text'
              value={values.testimonial_text}
              id='outlined-basic'
              label='Testimonial Text'
              variant='outlined'
              fullWidth
            />
            <CssTextField
              sx={{ margin: '6px 0' }}
              onChange={(e) => handleChange(e)}
              name='rating'
              value={values.rating}
              id='outlined-basic'
              label='Rating'
              variant='outlined'
              fullWidth
            />
            <Box
              sx={{
                margin: '24px 0',
              }}>
              {values.customer_photo ? (
                <div
                  style={{
                    border: '1px solid #E5E5E5',
                  }}>
                  {typeof values.customer_photo == 'string' ? (
                    <img
                      src={values.customer_photo}
                      width='128px'
                      height='128px'
                      alt='upload'
                    />
                  ) : (
                    <img
                      src={URL.createObjectURL(values.customer_photo)}
                      height='128px'
                      width='128px'
                      alt='upload'
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    padding: '12px',
                  }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#000000',
                      marginBottom: '8px',
                    }}>
                    Customer Photo
                  </Typography>
                  <label
                    style={{
                      border: '1px dashed black',
                      height: '128px',
                      width: '128px',
                      borderRadius: '8px',
                      padding: '12px',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#95989A',
                    }}>
                    <UploadFileOutlinedIcon
                      sx={{
                        width: '20px',
                        height: '23px',
                        marginBottom: '5px',
                      }}
                    />
                    <p>Tap to upload a photo</p>
                    <br />
                    <input
                      type='file'
                      name='customer_photo'
                      onChange={(e) => handleChange(e)}
                      accept='image/png , image/jpeg, image/webp'
                    />
                  </label>
                </div>
              )}
            </Box>
            <Box
              sx={{
                marginTop: values.customer_photo ? '-156px' : '-178px',
                marginRight: values.customer_photo ? '-100px' : '-118px',
              }}>
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, customer_photo: null })
                }}
                sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }}
              />
            </Box>
            <OrangeButton
              title='Save'
              sx={{
                marginTop: '150px',
              }}
              isDisabled={isDisabled}
              handleOnClick={() => (values.id ? handleUpdate() : handleSave())}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default TestimonialSection
