import { Box, Grid } from "@mui/material";
import React from "react";
import "./ThemeSettings.css";
import ThemeOne from "../../Images/theme_one.png";
import ThemeTwo from "../../Images/theme_two.png";
import ThemeThree from "../../Images/theme_three.jpg";
import ThemeFour from "../../Images/theme_four.jpg";
import SingleTheme from "./SingleTheme";

const data = [
  {
    id: 1,
    title: "Singularity",
    img: ThemeOne,
    previewLink: "https://demo.ebebsha.com/",
  },
  {
    id: 2,
    title: "Techne",
    img: ThemeTwo,
    previewLink: "https://techne.ebebsha.com/",
  },
  {
    id: 3,
    title: "Retro",
    img: ThemeThree,
    previewLink: "https://retro.ebebsha.com/",
  },
  {
    id: 4,
    title: "Solo",
    img: ThemeFour,
    previewLink: "https://solo.ebebsha.com/",
  },
];

const ThemeSettings = () => {
  return (
    <Box
      className="theme-container scroll"
      sx={{ overflowY: "auto", height: "83vh" }}
    >
      <Grid container spacing={2}>
        {data.map((theme, i) => (
          <SingleTheme key={i} data={theme} />
        ))}
      </Grid>
    </Box>
  );
};

export default ThemeSettings;
