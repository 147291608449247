import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import React, { useEffect, useState } from 'react'
import axios from '../../../InstanceAxios/InstanceAxios'
import { getShopInfo } from '../../../../utilities/StoredData'
import emptyInventory from '../../../../Images/add_image.png'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useNavigate } from 'react-router-dom'
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal'
import { NotificationContext } from '../../../../Contexts/NotificationContext'
import { useContext } from 'react'

const InventoryCollections = () => {
  const [collections, setCollections] = useState([])
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [willDeleteCollection, setWillDeleteCollection] = useState(null)
  const { showNotification } = useContext(NotificationContext)
  const navigate = useNavigate()

  const handleAddCollectionClick = () => {
    navigate('/inventory/collection/add')
  }

  const info = getShopInfo()

  useEffect(() => {
    const getCollections = async () => {
      const url = `/productCategories/allProductCategories?shop_id=${info?.shop_id}`
      const res = await axios.get(url)
      setCollections(res.data.Product_Categories)
    }
    getCollections()
  }, [info?.shop_id])

  const handleRowClick = (id) => {
    navigate(`/inventory/collection/${id}`)
  }

  const deleteCollection = async () => {
    try {
      setOpenConfirmationModal(false)
      const id = willDeleteCollection?.category_id
      const url = `/productCategories/deleteProductCategory/${id}`
      const res = await axios.delete(url)
      if (res.data.status === 'S117') {
        setCollections((prev) =>
          prev.filter((collection) => collection.category_id !== id)
        )
        showNotification('success', 'Collection deleted successfully')
      } else {
        showNotification('error', 'Failed to delete collection')
      }
    } catch (err) {
      showNotification('error', 'Failed to delete collection')
    } finally {
      setWillDeleteCollection(null)
    }
  }

  const handleDelete = (data) => {
    setOpenConfirmationModal(true)
    setWillDeleteCollection(data)
  }

  return (
    <div>
      <ConfirmationModal
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        handleAgree={deleteCollection}
        type='Collection'
        title={willDeleteCollection?.category}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          backgroundColor: '#FFFFFF',
          paddingY: '32px',
          paddingRight: '24px',
        }}>
        <Box>{/* invoice search */}</Box>
        <Button
          variant='contained'
          disableElevation
          onClick={handleAddCollectionClick}
          sx={{
            backgroundColor: 'var(--primary-bg-color)',
            fontFamily: 'Exo',
            textTransform: 'none',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: 'var(--primary-bg-color)',
              filter: 'brightness(1.1)',
              transition: 'all 250ms ease-in-out',
            },
          }}>
          <AddIcon sx={{ marginRight: '10px', width: 18 }} />
          Add New Collection
        </Button>
      </Box>

      {collections.length === 0 ? (
        <Box
          sx={{
            height: '70vh',
            display: 'flex',
            backgroundColor: '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <img
            height='230px'
            width='260px'
            src={emptyInventory}
            alt='no collection img'
          />
          <Typography sx={{ marginTop: '32px', color: '#95989A' }}>
            Your Inventory is empty, add a new Collection.
          </Typography>
        </Box>
      ) : (
        <Box>
          <Grid
            container
            sx={{
              marginY: '10px',
            }}>
            <Grid item md={6}>
              <Typography
                sx={{
                  textAlign: 'left',
                  marginLeft: '20px',
                  fontFamily: 'Exo',
                  fontWeight: 500,
                  color: 'var(--primary-text-color)',
                }}>
                Collection
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                sx={{
                  fontFamily: 'Exo',
                  fontWeight: 500,
                  color: 'var(--primary-text-color)',
                }}>
                Total In Stock
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                sx={{
                  fontFamily: 'Exo',
                  fontWeight: 500,
                  color: 'var(--primary-text-color)',
                }}>
                Out of Stock
              </Typography>
            </Grid>
            {/* <Grid item md={2}>
                            <Typography>Total Collection Value</Typography>
                        </Grid> */}
            {/* <Grid item md={2}>
                            <Typography></Typography>
                        </Grid> */}
          </Grid>
          <Box className='scroll' sx={{ overflowY: 'auto', height: '60vh' }}>
            {collections.map((collection) => (
              <>
                {' '}
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: '#FFFFFF',
                    paddingX: '17px',
                    '&:hover': {
                      backgroundColor: '#F8F8F8',
                    },
                  }}>
                  <Grid
                    container
                    sx={{ paddingY: '10px' }}
                    onClick={() => handleRowClick(collection.category_id)}>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <img
                          width='64px'
                          height='64px'
                          src={collection?.img_url}
                          alt='product Img'
                        />
                        <Typography
                          sx={{
                            textAlign: 'left',
                            marginLeft: '16px',
                            fontFamily: 'Exo',
                            fontWeight: 500,
                            color: 'var(--primary-text-color)',
                          }}>
                          {collection?.category}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2}>
                      <Typography
                        sx={{
                          paddingTop: '20px',
                          fontFamily: 'Exo',
                          fontWeight: 500,
                          color: 'var(--primary-text-color)',
                        }}>
                        {collection?.in_stock} Items
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography
                        sx={{
                          paddingTop: '20px',
                          fontFamily: 'Exo',
                          fontWeight: 500,
                          color: 'var(--primary-text-color)',
                        }}>
                        {collection?.out_of_stock}
                      </Typography>
                    </Grid>
                    {/* <Grid item md={2}>
                                        <Typography sx={{ paddingTop: '20px', textAlign: 'center' }}><span style={{ fontFamily: 'NotoSansBengali' }}>৳ </span>{collection?.unit_selling_price}</Typography>
                                    </Grid> */}
                    {/* <Grid item md={2}>
                                       
                                    </Grid> */}
                  </Grid>
                  <DeleteOutlineIcon
                    onClick={() => handleDelete(collection)}
                    sx={{
                      color: 'var(--primary-text-color)',
                      marginTop: '30px',
                    }}
                  />

                  <Dialog>
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <DialogContentText></DialogContentText>
                    </DialogContent>
                    <DialogActions></DialogActions>
                  </Dialog>
                </Box>
                <hr style={{ border: '.5px solid #E6E6E6', margin: '0px' }} />
              </>
            ))}
          </Box>
        </Box>
      )}
    </div>
  )
}

export default InventoryCollections
