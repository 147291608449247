import React, { useState } from 'react'
import { Box, Typography, Radio } from '@mui/material'

const SelectCollection = ({ collections, handleClickCollections }) => {
  let radio = []
  collections.map((r) => {
    radio.push(false)
  })

  const [checked, setChecked] = useState(radio)

  console.log(checked)

  const handleClick = (e, t, id) => {
    let temp = [...checked]
    temp[id] = true
    setChecked(temp)
    handleClickCollections(e, t)
  }
  // console.log(checked);

  return (
    <div>
      {collections.map((collection, index) => (
        <>
          {' '}
          <Box
            onClick={() =>
              handleClick(collection.category_id, collection.category, index)
            }
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingX: '24px',
              marginY: '16px',
            }}>
            <Box sx={{ display: 'flex' }}>
              <img
                width='48px'
                style={{ marginRight: '16px' }}
                src={collection.img_url}
                alt='collection img'
              />
              <Box>
                <Typography
                  sx={{ fontSize: '16px', color: 'var(--primary-text-color)' }}>
                  {collection.category}
                </Typography>
                <Typography sx={{ fontSize: '12px', color: '#95989A' }}>
                  In Stock: {collection.in_stock} Items
                </Typography>
              </Box>
            </Box>
            <Radio
              checked={checked[index]}
              onClick={() =>
                handleClick(collection.category_id, collection.category, index)
              }
              sx={{ zIndex: '2', marginLeft: '60px', marginBottom: '-35px' }}
              // value={variantProd[index].image_index}
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
            />
          </Box>
          <hr style={{ border: '1px solid #F2F2F2' }} />
        </>
      ))}
    </div>
  )
}

export default SelectCollection
