import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import jwtDecode from 'jwt-decode'
import axios from '../InstanceAxios/InstanceAxios'
import { toast, ToastContainer } from 'react-toastify'
import { AuthContext } from '../../Contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import OrangeOutlinedButton from '../../components/Buttons/OrangeOutlinedButton/OrangeOutlinedButton'
import { NotificationContext } from '../../Contexts/NotificationContext'

const SingleTheme = (props) => {
  const navigate = useNavigate()
  const { shopDetails, getShopDetails, subscribedPlan } =
    useContext(AuthContext)
  const { showNotification } = useContext(NotificationContext)

  const handleConfigure = (id) => {
    const theme = subscribedPlan.themes.find((tm) => tm.themes.id === id)
    if (theme) {
      if (id !== 4) {
        navigate('/settings/theme-settings/featured-products')
      } else {
        navigate('/settings/theme-settings/solo-theme-select-product')
      }
    } else {
      navigate('/settings/subscription')
    }
  }

  const handleApply = () => {
    const theme = subscribedPlan.themes.find(
      (tm) => tm.themes.id === props.data.id
    )
    if (theme) {
      handleSubmit(props.data.id)
    } else {
      showNotification(
        'error',
        `Your current plan doesn't have access to ${props?.data?.title} Theme. Please Upgrade`
      )
      navigate('/settings/subscription')
    }
  }

  const handleSubmit = async (id) => {
    try {
      const { shop_id } = jwtDecode(localStorage.getItem('token'))
      const url = `/merchants/updateShopTheme/${shop_id}`
      const res = await axios.put(url, {
        theme_id: id,
      })
      if (res.data.status === 'S116') {
        toast.success('Theme Applied successfully', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
      } else {
        toast.failed('Failed to apply theme', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    } catch (error) {
      console.log(error)
      toast.failed('Failed to apply theme', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
    } finally {
      getShopDetails()
    }
  }

  return (
    <Grid item md={6}>
      <ToastContainer autoClose={5000} closeOnClick />
      <Box sx={{ backgroundColor: 'white', margin: '0 12px', padding: '16px' }}>
        <img src={props.data.img} alt='' />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            textAlign: 'left',
          }}
        >
          <Box sx={{ marginTop: '16px' }}>
            <Typography
              sx={{
                color: 'var(--primary-text-color)',
                fontSize: '22px',
                fontFamily: 'Exo',
              }}
            >
              {props.data.title}
            </Typography>
            <Typography
              sx={{
                color: '#95989A',
                fontSize: '14px',
                fontFamily: 'Exo',
              }}
            >
              Light
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
              flexWrap: 'wrap',
            }}
          >
            {shopDetails?.theme_id === props.data.id ? (
              <Button
                disableElevation
                variant='contained'
                sx={{
                  backgroundColor: 'var(--primary-bg-color)',
                  fontFamily: 'Exo',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'var(--primary-bg-color)',
                    fiter: 'brightness(1.1)',
                  },
                }}
                onClick={() => handleConfigure(props.data.id)}
              >
                Configure
              </Button>
            ) : (
              <OrangeOutlinedButton
                title='Preview'
                icon={
                  <VisibilityOutlinedIcon
                    sx={{ marginRight: '10px', width: 18 }}
                  />
                }
                sx={{
                  color: 'var(--primary-bg-color)',
                  border: '1px solid var(--primary-bg-color)',
                  '&:hover': {
                    color: 'var(--primary-bg-color)',
                    border: '1px solid var(--primary-bg-color)',
                  },
                }}
                handleOnClick={() =>
                  window.open(props.data.previewLink, '_blank')
                }
              />
            )}

            <Button
              disableElevation
              variant='contained'
              sx={{
                backgroundColor: 'var(--primary-bg-color)',
                fontFamily: 'Exo',
                textTransform: 'none',
                '&:hover': {
                  // borderColor: '# ED5E4A',
                  backgroundColor: 'var(--primary-bg-color)',
                  fiter: 'brightness(1.1)',
                },
              }}
              onClick={() => handleApply(props.data.id)}
              disabled={shopDetails?.theme_id === props.data.id}
            >
              {shopDetails?.theme_id === props.data.id ? 'Applied' : 'Apply'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default SingleTheme
