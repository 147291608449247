import { Button } from '@mui/material'
import React from 'react'

const DarkButton = ({ title, handleOnClick, sx, icon, isChip = false }) => {
  return (
    <Button
      variant='contained'
      disableElevation={isChip}
      sx={{
        backgroundColor: 'var(--primary-text-color)',
        color: '#FFFFFF',
        borderRadius: '6px',
        padding: '8px 16px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        fontFamily: 'Exo',
        textTransform: 'none',
        border: '1px solid var(--primary-text-color)',
        '&:hover': {
          backgroundColor: 'var(--primary-text-color)',
          color: '#FFFFFF',
        },
        ...sx,
      }}
      onClick={handleOnClick}>
      {icon}
      {title}
    </Button>
  )
}

export default DarkButton
