import React, { useContext } from 'react'
import { useGoogleLogin } from 'react-google-login'
import Box from '@mui/material/Box'

// refresh token
import { refreshTokenSetup } from '../../utilities/refreshToken'
import GoogleIcon from '../../Images/google.svg'
import { AuthContext } from '../../Contexts/AuthContext'

const clientId = process.env.REACT_APP_CLIENTID

function LoginHooks({ type = 'Sign Up' }) {
  const { googleLogin } = useContext(AuthContext)
  const onSuccess = async (res) => {
    refreshTokenSetup(res)
    googleLogin(res.tokenObj.id_token)
  }

  const onFailure = (res) => {
    console.log('Login failed: res:', res)
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
    // responseType: 'code',
    // prompt: 'consent',
  })

  return (
    <div className='google-login'>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          border: '.05px solid rgba(78,79,114,.1)',
          borderRadius: '4px',
          boxSizing: 'initial',
          cursor: 'pointer',
          fontSize: '15px',
          margin: '16px 0',
          padding: '15.2px 20px',
          position: 'relative',
          width: '90%',
          img: {
            width: '24px',
            height: '24px',
          },
        }}
        onClick={signIn}
      >
        <img src={GoogleIcon} alt='google login'></img>
        <span className='buttonText'>{type} with Google</span>
      </Box>
    </div>
  )
}

export default LoginHooks
