import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  styled,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import CachedIcon from '@mui/icons-material/Cached'
import { Box } from '@mui/system'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import jwtDecode from 'jwt-decode'
import axios from '../InstanceAxios/InstanceAxios'
import SelectProduct from './SelectProduct'
import { toast, ToastContainer } from 'react-toastify'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--primary-text-color)',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'var(--primary-text-color)',
    },
  },
})

const AddFeaturedProduct = ({ isAdd: fp_id, setIsAdd, featuredProducts }) => {
  const [values, setValues] = useState({
    title: '',
    description: '',
    product_id: '',
    product_name: '',
  })
  const [products, setProducts] = useState([])
  const [image, setImage] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentFeatured, setCurrentFeatured] = useState({})
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSelectFile = (e) => {
    setImage(e.target.files[0])
  }

  const handleChange = (event) => {
    const oldValues = { ...values }
    oldValues[event.target.name] = event.target.value
    setValues({ ...oldValues })
  }

  const handleSubmit = async () => {
    try {
      setIsDisabled(true)
      const { shop_id } = jwtDecode(localStorage.getItem('token'))
      const formData = new FormData()
      let res = {}
      if (isEdit) {
        formData.append('title', values.title)
        formData.append('description', values.description)
        formData.append('product_id', values.product_id)
        if (typeof image != 'string') {
          formData.append('image', image)
        }
        const url = `/products/featured/updateFeaturedProduct/${currentFeatured.id}`
        res = await axios.put(url, formData)
      } else {
        formData.append('title', values.title)
        formData.append('description', values.description)
        formData.append('product_id', values.product_id)
        formData.append('fp_id', fp_id)
        formData.append('image', image)
        formData.append('shop_id', shop_id)
        const url = `/products/featured`
        res = await axios.post(url, formData)
      }
      if (res.data.status === 'S118' || res.data.status === 'S123') {
        toast.success('Featured product saved successfully', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
        setIsAdd(0)
      } else {
        toast.error('Failed to save Featured product', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    } catch (error) {
      console.log(error)
      toast.error('Failed to save Featured product', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
    }
  }

  const getProducts = async () => {
    try {
      const { shop_id } = jwtDecode(localStorage.getItem('token'))
      const url = `/products/getProductsV3/${shop_id}?status=all`
      const res = await axios.get(url)
      setProducts(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleClickProduct = (index) => {
    const oldValues = { ...values }
    oldValues.product_id = products[index].product_id
    oldValues.product_name = products[index].product_name
    setValues(oldValues)
  }

  useEffect(() => {
    if (
      values.title.length &&
      values.description.length &&
      values.product_id >= 0 &&
      image !== null
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [values.title, values.description, values.product_id, image])

  useEffect(() => {
    if (Object.keys(currentFeatured).length !== 0) {
      const product = products.find(
        (p) => p.product_id === currentFeatured.product_id
      )
      const newValues = {
        title: currentFeatured.title,
        description: currentFeatured.description,
        product_id: product.product_id,
        product_name: product.product_name,
      }
      setValues(newValues)
      setImage(currentFeatured.img_url)
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [currentFeatured])

  useEffect(() => {
    if (products.length && featuredProducts.length) {
      setCurrentFeatured(
        featuredProducts.find((fp) => fp.fp_id === fp_id) || {}
      )
    }
  }, [fp_id, featuredProducts, products])

  useEffect(() => {
    getProducts()
  }, [])

  return (
    <div className='add-feature-container'>
      <ToastContainer autoClose={5000} closeOnClick />
      <Grid container>
        <Grid item lg={8} sx={{ textAlign: 'left' }}>
          <Typography
            sx={{
              textAlign: 'left',
              color: 'var(--primary-text-color)',
              fontSize: '22px',
              marginBottom: '16px',
            }}
          >
            About Feature
          </Typography>
          <CssTextField
            sx={{ margin: '6px 0' }}
            onChange={(e) => handleChange(e)}
            name='title'
            value={values.title}
            id='outlined-basic'
            label='Feature Title'
            variant='outlined'
            placeholder='Select Featured'
            fullWidth
          />
          <CssTextField
            disabled
            sx={{ margin: '6px 0' }}
            onClick={handleClickOpen}
            name='product_id'
            value={values.product_name}
            id='outlined-basic'
            label='Select Featured'
            variant='outlined'
            placeholder='Enter a title'
            fullWidth
          />
          <Dialog fullWidth open={open} onClose={handleClose}>
            <DialogTitle>Select Product</DialogTitle>
            <DialogContent
              className='scroll'
              sx={{ paddingX: '0px', height: '50%' }}
            >
              <Box onClick={handleClose}>
                <SelectProduct
                  products={products}
                  selectedProduct={values.product_id}
                  handleClickProduct={handleClickProduct}
                />
              </Box>
            </DialogContent>
          </Dialog>
          <CssTextField
            sx={{ margin: '6px 0' }}
            onChange={(e) => handleChange(e)}
            name='description'
            value={values.description}
            multiline
            rows={8}
            id='outlined-basic'
            label='Feature Description'
            variant='outlined'
            placeholder='Enter a title'
            fullWidth
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '6px 0' }}>
            {image ? (
              <Box>
                <div>
                  {typeof image == 'string' ? (
                    <img
                      src={image}
                      width='160px'
                      height='160px'
                      style={{ borderRadius: '4px', marginRight: '24px' }}
                      alt='upload'
                    />
                  ) : (
                    <img
                      src={URL.createObjectURL(image)}
                      height='160px'
                      width='160px'
                      style={{ borderRadius: '4px', marginRight: '24px' }}
                      alt='upload'
                    />
                  )}
                </div>
              </Box>
            ) : (
              <div>
                <label className='addImage-div'>
                  <UploadFileOutlinedIcon
                    sx={{ width: '20px', height: '23px', marginBottom: '5px' }}
                  />
                  <p>
                    Tap to upload a featured cover here <br />
                    (766 x 576) pixel
                  </p>
                  <br />
                  <input
                    type='file'
                    name='image'
                    onChange={(e) => onSelectFile(e)}
                    accept='image/png , image/jpeg, image/webp'
                  />
                </label>
              </div>
            )}
          </Box>
          <Button
            variant='outlined'
            sx={{
              textTransform: 'none',
              color: 'var(--primary-bg-color)',
              borderColor: 'var(--primary-bg-color)',
              '&:hover': {
                borderColor: 'var(--primary-bg-color)',
              },
              borderRadius: '8px',
              margin: '10px 0',
              width: '160px',
            }}
            onClick={() => setImage(null)}
          >
            <CachedIcon fontSize='small' sx={{ marginRight: '10px' }} /> Change
            Logo
          </Button>
        </Grid>
      </Grid>
      <div className='botton-div'>
        <Button
          variant='contained'
          sx={{
            backgroundColor: 'var(--primary-bg-color)',
            '&:hover': {
              borderColor: 'var(--primary-bg-color)',
              backgroundColor: 'var(--primary-bg-color)',
            },
          }}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          {isEdit ? 'Edit' : 'Add'} Feature Product
        </Button>
      </div>
    </div>
  )
}

export default AddFeaturedProduct
