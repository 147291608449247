import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
  },
  withCredentials: true,
})

instance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    config.headers.refresh = localStorage.getItem('refreshToken')
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      // localStorage.removeItem('token');
    }
    return Promise.reject(error)
  }
)

export default instance
