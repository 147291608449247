import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../../InstanceAxios/InstanceAxios'

const SpecificCustomerOrder = ({ cuid }) => {
  const [customer, setCustomer] = useState([])
  const navigate = useNavigate()
  var moment = require('moment')
  useEffect(() => {
    const url = `sales/getAllSalesByCustomer/${cuid}`
    const getDetail = async () => {
      const res = await axios.get(url)
      setCustomer(
        res.data.map((item, index) => ({
          ...item,
          items: `${item.ordered_items.length} product(s)`,
        }))
      )
    }
    getDetail()
  }, [cuid])

  const rows = customer

  const columns = [
    { field: 'serial', headerName: 'Order Id', width: 120 },
    { field: 'formated_datetime', headerName: 'Date & Time', width: 246 },
    { field: 'items', headerName: 'Items', width: 200 },

    // {
    //     field: 'Item',
    //     headerName: 'Item',
    //     width: 246,
    // },
    {
      field: 'total_amount',
      headerName: 'Total Amount',
      sortable: false,
      width: 200,
    },
    {
      field: 'payment_method',
      headerName: 'Payment Method',
      sortable: false,
      width: 200,
    },
    {
      field: 'action',
      headerName: 'Status',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        //     const urlAccept = `/sales/acceptOrder/${params.row.sales_id}`
        //     const urlCancel = `/sales/updateOrderStatus/${params.row.sales_id}`
        //     const onClickAccept = async e => {
        //         e.stopPropagation();
        //         console.log(params.row.delivery_partner);
        //         const res = await axios.put(urlAccept,
        //             {
        //                 "delivery_partner": params.row.delivery_partner
        //             })
        //         console.log(res.data);
        //         setSelected(1)
        //         setRender(params.row.sales_id)

        //     }
        //     const onClickCancel = async e => {
        //         console.log('cancel');
        //         e.stopPropagation();
        //         const res = await axios.put(urlCancel,
        //             {
        //                 order_status: 7
        //             })
        //         console.log(res.data);
        //         setSelected(1)
        //         setRender(params.row.sales_id)
        //     }

        return (
          <>
            {params.row?.order_status === 1 && (
              <Box>
                <Button
                  // onClick={onClickAccept}
                  disableElevation
                  variant='contained'
                  sx={{
                    marginRight: '8px',
                    width: '96px',
                    borderRadius: '6px',
                    fontFamily: 'Exo',
                    fontWeight: 500,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid var(--primary-text-color)',
                    textTransform: 'none',
                    color: 'var(--primary-text-color)',
                    '&:hover': {
                      border: '1px solid var(--primary-text-color)',
                      backgroundColor: '#EFF3F8',
                      color: 'var(--primary-text-color)',
                    },
                  }}>
                  pending
                </Button>
              </Box>
            )}
            {params.row?.order_status === 2 && (
              <Box>
                <Button
                  disableElevation
                  variant='contained'
                  className='order-status'
                  sx={{
                    marginRight: '8px',
                    borderRadius: '6px',
                    width: '96px',
                    fontFamily: 'Exo',
                    fontWeight: 500,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid var(--primary-text-color)',
                    textTransform: 'none',
                    color: 'var(--primary-text-color)',
                    '&:hover': {
                      border: '1px solid var(--primary-text-color)',
                      backgroundColor: '#EFF3F8',
                      color: 'var(--primary-text-color)',
                    },
                  }}>
                  Accepted
                </Button>
              </Box>
            )}
            {params.row?.order_status === 3 && (
              <Box>
                <Button
                  disableElevation
                  variant='contained'
                  sx={{
                    marginRight: '8px',
                    borderRadius: '6px',
                    width: '96px',
                    fontFamily: 'Exo',
                    fontWeight: 500,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid var(--primary-text-color)',
                    textTransform: 'none',
                    color: 'var(--primary-text-color)',
                    '&:hover': {
                      border: '1px solid var(--primary-text-color)',
                      backgroundColor: '#EFF3F8',
                      color: 'var(--primary-text-color)',
                    },
                  }}>
                  Picked Up
                  {/* <KeyboardArrowDownIcon /> */}
                </Button>
              </Box>
            )}
            {params.row?.order_status === 4 && (
              <Box>
                <Button
                  disableElevation
                  variant='contained'
                  sx={{
                    marginRight: '8px',
                    borderRadius: '6px',
                    width: '96px',
                    fontFamily: 'Exo',
                    fontWeight: 500,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid var(--primary-text-color)',
                    textTransform: 'none',
                    color: 'var(--primary-text-color)',
                    '&:hover': {
                      border: '1px solid var(--primary-text-color)',
                      backgroundColor: '#EFF3F8',
                      color: 'var(--primary-text-color)',
                    },
                  }}>
                  In Transit
                  {/* <KeyboardArrowDownIcon /> */}
                </Button>
              </Box>
            )}
            {params.row?.order_status === 5 && (
              <Box>
                <Button
                  disableElevation
                  variant='contained'
                  sx={{
                    marginRight: '8px',
                    borderRadius: '6px',
                    width: '96px',
                    fontFamily: 'Exo',
                    fontWeight: 500,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid var(--primary-text-color)',
                    textTransform: 'none',
                    color: 'var(--primary-text-color)',
                    '&:hover': {
                      border: '1px solid var(--primary-text-color)',
                      backgroundColor: '#EFF3F8',
                      color: 'var(--primary-text-color)',
                    },
                  }}>
                  Delivered
                  {/* <KeyboardArrowDownIcon /> */}
                </Button>
              </Box>
            )}
            {params.row?.order_status === 6 && (
              <Box>
                <Button
                  disableElevation
                  variant='contained'
                  sx={{
                    marginRight: '8px',
                    borderRadius: '6px',
                    width: '96px',
                    fontFamily: 'Exo',
                    fontWeight: 500,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid var(--primary-text-color)',
                    textTransform: 'none',
                    color: 'var(--primary-text-color)',
                    '&:hover': {
                      border: '1px solid var(--primary-text-color)',
                      backgroundColor: '#EFF3F8',
                      color: 'var(--primary-text-color)',
                    },
                  }}>
                  Returned
                </Button>
              </Box>
            )}
            {params.row?.order_status === 7 && (
              <Box>
                <Button
                  variant='contained'
                  disabled
                  sx={{
                    marginRight: '8px',
                    borderRadius: '6px',
                    width: '96px',
                    fontFamily: 'Exo',
                    fontWeight: 500,
                    textTransform: 'none',
                  }}>
                  Cancelled
                </Button>
              </Box>
            )}
          </>
        )
      },
    },
  ]

  const handleRowClick = (params) => {
    // console.log(params.row.serial);
    navigate(`/orders/${params.row.sales_id}`)
  }

  return (
    <DataGrid
      getRowId={(row) => row.serial}
      rowHeight={64}
      rows={rows}
      columns={columns}
      pageSize={6}
      rowsPerPageOptions={[4]}
      sx={{
        backgroundColor: '#FFFFFF',
        fontFamily: 'Exo',
        fontWeight: 500,
        height: '40vh',
        margin: '0 28px',
        color: 'var(--primary-text-color)',
        border: 'none',
      }}
      onRowClick={handleRowClick}
      // checkboxSelection
    />
  )
}

export default SpecificCustomerOrder
